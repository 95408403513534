import { useObservableState } from 'observable-hooks';
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';
import { useLongPress } from 'use-long-press';
import TopMenu from '../../TopMenu';
import { useImageUpdates } from '../../hooks/useImageUpdates';
import { ArrowDownIcon, ArrowTopIcon, BackIcon, HourglassIcon, PlayIcon, SelectableIcon, SelectedIcon } from '../../icons/Icons';
import backendService from '../../services/BackendService';
import collectionsService from '../../services/CollectionsService';
import imagesService from '../../services/ImagesService';
import routerService from '../../services/RouterService';
import { Image } from '../../types';
import { ItemBottomMenu, MenuItem } from '../bottomMenu/ItemBottomMenu';
import { AddToCollectionDialog } from '../dialogs/AddToCollectionDialog';
import { CreateCollectionDialog } from '../dialogs/CreateCollectionDialog';
import { Collections } from './Collections';
import classes from './GalleryPage.module.scss';
import { SimpleBottomMenu } from '../bottomMenu/SimpleBottomMenu';
import { CopyOrMoveCollectionDialog } from '../dialogs/CopyOrMoveCollectionDialog';
import classNames from 'classnames';

export function GalleryPage() {
  const navigate = useNavigate();
  const images = useObservableState(imagesService.onImages(), []);
  
  const [fullCollectionPreview, setFullCollectionPreview] = useState(false);

  const [addingToCollection, setAddingToCollection] = useState(false);
  const [addingToCollectionType, setAddingToCollectionType] = useState<'new' | 'select' | undefined>(undefined); 

  const [creatingCollection, setCreatingCollection] = useState(false);
  const [createEmptyCollection, setCreateEmptyCollection] = useState(false);

  const [selectCollection, setSelectCollection] = useState(false);
  const [selectCopyOrMove, setSelectCopyOrMove] = useState(false);
  
  const [selectedCollection, setSelectedCollection] = useState<string | undefined>(undefined);
  
  const [inSelectionMode, setInSelectionMode] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]); 

  const imageLongPressBind = useLongPress(() => {
    if ('vibrate' in navigator) navigator.vibrate([100, 100, 100]);
    setInSelectionMode(!inSelectionMode)
  },
  {
    filterEvents: (event) => true,
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: true
  });

  useImageUpdates(images);

  const onImageClicked = (id: string) => {
    const index = selectedImages.findIndex(x => x === id);
    if (index === -1) {
      setSelectedImages([...selectedImages, id]);
    }
    else {
      selectedImages.splice(index, 1);
      setSelectedImages([...selectedImages]);
    }
  }

  const onAddingToCollection = (type: 'new' | 'select' | 'close') => {
    if (type === 'new') {
      setAddingToCollection(false);
      setAddingToCollectionType('new');
      setCreatingCollection(true);
    }
    else if (type === 'select') {
      setAddingToCollection(false);
      setAddingToCollectionType('select');
      setSelectCollection(true);
      setFullCollectionPreview(true);
    }
    else {
      setAddingToCollectionType(undefined);
      setAddingToCollection(false);
    }
  }

  const onCreateCollection = (name: string) => {
    setCreatingCollection(false);
    setSelectedCollection(name);

    if (createEmptyCollection) {
      collectionsService.createCollection(name, false, []);
      setCreateEmptyCollection(false);
    }
    else {
      setSelectCopyOrMove(true);
    }
  }

  const onCopyOrMoveToCollection = (type: 'copy' | 'move' | 'close') => {
    
    setSelectCopyOrMove(false);

    if (type !== 'close') {
      const copyImages = type === 'copy';
      
      setInSelectionMode(false);

      if (addingToCollectionType === 'new') {
        console.log(`Creating collection with copy: ${copyImages}`);
        collectionsService.createCollection(selectedCollection!, copyImages, selectedImages);
      }
      else {
        console.log(`Adding images to collection with copy: ${copyImages}`);
        collectionsService.addImages(selectedCollection!, copyImages, selectedImages);
      }
        
      setSelectedImages([]);
      setSelectedCollection(undefined);
      setAddingToCollectionType(undefined);
    }
  }

  const onShare = () => {
    alert('not implemented');
  }

  const onDelete = () => {
    imagesService.deleteImages(selectedImages.map(x => imagesService.imageById(x)!));
    setInSelectionMode(false);
    setSelectedImages([]);
  }

  const menuItems: MenuItem[] =
  [
    {
      title: 'edit selected',
      icon: 'edit',
      onClick: () => { alert('not implemented'); }
    },
    {
      title: 'share',
      icon: 'share',
      onClick: onShare,
    },
    {
      title: 'add to collection',
      icon: 'add',
      onClick: () => setAddingToCollection(true),
    },
    {
      title: 'delete',
      icon: 'delete',
      onClick: onDelete,
    }
  ]

  const renderImageItem = (image: Image, i: number, onClick: () => void) => {
    return <div onClick={onClick}>
      <div className={classes.iconContainer}>
        { inSelectionMode 
          ? selectedImages.find(x => x === image.imageId) !== undefined
            ? <SelectedIcon className={classes.selectedIcon}></SelectedIcon>
            : <SelectableIcon className={classes.selectedIcon}></SelectableIcon>
           : null }
      </div>

      <div className={classes.iconContainer}>
        {image.thumbnailDataUrl !== undefined || image.isRendering ? <HourglassIcon className={classes.imageIcon}></HourglassIcon> : null }
        {image.isVideo && !image.isRendering ? <PlayIcon className={classes.imageIcon}></PlayIcon> : null }
      </div>

      <img className={classNames(classes.image, selectedImages.find(x => x === image.imageId) !== undefined ? classes.selectedImage : null)} src={image.thumbnailDataUrl ? image.thumbnailDataUrl : backendService.getGetImageThumbnailUrl(image.imageId, image.renderType)} alt={`pattern${i + 1}`}></img>
    </div>;
  }

  const onSave = selectedCollection !== undefined ? () => {
    setSelectCollection(false);
    setSelectCopyOrMove(true);
    setFullCollectionPreview(false);
  } : undefined;

  return <div className={classes.page}>
    <TopMenu />

    { addingToCollection
      ? <AddToCollectionDialog onSelection={onAddingToCollection}></AddToCollectionDialog>
      : null }

    { creatingCollection
      ? <CreateCollectionDialog onClose={() => setCreatingCollection(false)} onCreate={onCreateCollection}></CreateCollectionDialog>
      : null }


    { selectCopyOrMove
      ? <CopyOrMoveCollectionDialog onSelection={onCopyOrMoveToCollection}></CopyOrMoveCollectionDialog>
      : null}

    <div className={classes.header}>
      <div className={classes.left} onClick={_ => navigate('/camera')}><BackIcon className={classes.icon}></BackIcon></div>
      <div className={classes.middle}>My patterns</div>
      <div className={classes.right} onClick={_ => setFullCollectionPreview(!fullCollectionPreview)}>
        { fullCollectionPreview ? <ArrowTopIcon className={classes.icon}></ArrowTopIcon> : <ArrowDownIcon className={classes.icon}></ArrowDownIcon>}
      </div>
    </div>

    <Collections selectionMode={selectCollection} fullMode={fullCollectionPreview} onCreateNewCollection={() => { setCreatingCollection(true); setCreateEmptyCollection(true); }} selectedCollection={selectedCollection} onCollectionSelected={setSelectedCollection}></Collections>

    <div className={classes.imageContainer}>
      {images.filter(x => !x.collectionId).map((image, i) => 
        <div {...imageLongPressBind()} className={classes.imageItem} key={`${image.imageId}-${image.renderType}`}>
          { inSelectionMode
            ? renderImageItem(image, i, () => onImageClicked(image.imageId))
            : renderImageItem(image, i, () => { if (!image.isRendering && !selectCollection) { navigate(routerService.getFullscreenImageUrl(image)); } }) }
        </div>)}
    </div>

    { inSelectionMode && selectedImages.length > 0 && !selectCollection ? <ItemBottomMenu menuItems={menuItems} onBack={() => { setSelectedImages([]); setInSelectionMode(false); }}></ItemBottomMenu> : null }

    { selectCollection ? <SimpleBottomMenu title='Add to collection' onBack={() => { setSelectCollection(false); setFullCollectionPreview(false); }} onSave={onSave}></SimpleBottomMenu> : null }
  </div>;
}


