import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import backendService from '../../services/BackendService';
import imagesService from '../../services/ImagesService';
import routerService from '../../services/RouterService';
import { useImageSearchParams } from '../../hooks/useImageSearchParams';
import { ItemBottomMenu, MenuItem } from '../bottomMenu/ItemBottomMenu';
import classes from './FullscreenImagePage.module.scss';
import { useObservableState } from 'observable-hooks';
import { updateImageParameters } from '../../utils/updateImageParamters';
import { LoadingOverload } from '../overlay/LoadingOverload';


export function FullscreenImagePage() {
  const params = useImageSearchParams();
  const queryParams = useSearchParams()[0];
  const navigate = useNavigate();
  const images = useObservableState(imagesService.onImages(), []);
  const [menuLevel, setMenuLevel] = useState(parseInt(queryParams.get('menuLevel') ?? '1'));
  const [loading, setLoading] = useState(true);

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => navigateToNextImage(1),
    onSwipedRight: () => navigateToNextImage(-1),
  });

  const image = images.find(x => x.imageId === params.imageId);
  useEffect(() => {
    if (image === undefined) {
      
    }
  });
  
  if (image === undefined) {
    return <div></div>;
  }

  const imageParameter = updateImageParameters(params, image);

  const navigateToNextImage = (offset: number) => {
    const images = imagesService.allImages();
    if (images.length === 0) {
      navigate('/camera');
    }

    const index = images.findIndex(x => x.imageId === params.imageId) + offset;
    if (index >= 0 && index < imagesService.allImages().length) {
      navigate(routerService.getFullscreenImageUrl(imagesService.allImages()[index]));
    }
  }

  const onBack = () => {
    queryParams.get('fromCamera') === 'true' ? navigate('/camera') : navigate('/gallery');
  }

  const onSave = () => {
    setLoading(true);
    
    const newImage = {
      ...image,
      ...imageParameter
    };

    setMenuLevel(1);

    imagesService.saveCopyOfImage(newImage, newImageId => {
      navigate(routerService.getFullscreenImageUrl({ imageId: newImageId }));
    });
  };

  const onShare = () => {
    fetch(backendService.getGetImageUrl(params.imageId))
      .then(x => x.blob())
      .then(x => {
        navigator.share({
          title: 'PatternIt!',
          text: 'From PatternIt',
          files: [new File([x], 'PatternIt.png', { type: x.type })]
        });
      });
  };

  const onDelete = () => {
    const image = imagesService.imageById(params.imageId);
    if (image !== null) {
      imagesService.deleteImages([image]);
      onBack();
    }
  };

  const level1MenuItems: MenuItem[] = !image.isVideo
  ? [
      {
        title: 'edit',
        icon: 'edit',
        onClick: () => setMenuLevel(2)
      },
      {
        title: 'share',
        icon: 'share',
        onClick: onShare,
      },
      {
        title: 'add',
        icon: 'add',
        onClick: () => {}
      },
      {
        title: 'delete',
        icon: 'delete',
        onClick: onDelete,
      }
    ]
  : [
    {
      title: 'share',
      icon: 'share',
      onClick: onShare,
    },
    {
      title: 'add',
      icon: 'add',
      onClick: () => {}
    },
    {
      title: 'delete',
      icon: 'delete',
      onClick: onDelete,
    }
  ];

  const level2MenuItems: MenuItem[] = 
  [
    {
      title: 'repattern',
      icon: 'repattern',
      onClick: () => navigate(routerService.getRepatternImageUrl(imageParameter))
    },
    {
      title: 'adjust',
      icon: 'adjust',
      onClick: () => navigate(routerService.getAdjustImageUrl(imageParameter))
    }
  ];

  return <div {...swipeableHandlers} className={classes.page}>
      <div onClick={_ => onBack()} className={classes.close}>
        <svg width='50px' height='50px' viewBox="0 0 100 100" stroke='var(--pt-text-color-light)'><path strokeWidth='12' strokeLinecap='square' d='M 10,10 90,90 M 10,90 90,10'/></svg>
      </div>

      {!image.isVideo 
        ? <img onLoad={() => setLoading(false)} className={classes.image} src={backendService.getGetImagePreviewUrl(imageParameter)} alt='pattern'></img>
        : <video className={classes.image} onCanPlay={() => setLoading(false)} src={backendService.getVideoUrl(imageParameter)} autoPlay={true} loop={true} muted={true} playsInline={true}></video>
      }

      { loading ? <LoadingOverload></LoadingOverload> : null}

      <ItemBottomMenu
        menuItems={menuLevel === 1 ? level1MenuItems : level2MenuItems}
        onBack={menuLevel === 2 ? () => setMenuLevel(1) : undefined}
        onSave={menuLevel === 2 ? () => onSave() : undefined} ></ItemBottomMenu>
  </div>
}
