import classNames from 'classnames';

import { getFilterIcon } from '../../icons/getFilterIcon';
import { FilterType } from '../../types';
import classes from './BottomMenu.module.scss';
import { useEffect } from 'react';

interface FilterAdjustBottomMenuItemProps {
  filterType: FilterType;
  onFilterTypeChanged: (filterType: FilterType) => void;
}

export function FilterAdjustBottomMenuItem(props: FilterAdjustBottomMenuItemProps) {
  useEffect(() => {
    document
      .getElementById(FilterType[props.filterType])
      ?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'center' });
  }, [props.filterType]);

  return <div className={classes.patternContainer}>
  { allFilters.map(x => (
      <div onClick={() => props.onFilterTypeChanged(x)} className={classNames(classes.item, props.filterType === x ? classes.selected : null)} id={FilterType[x]} key={FilterType[x]}>
        { getFilterIcon(x, classNames(classes.patternIcon, props.filterType === x ? classes.selected : null))} 
        <div className={classes.patternTitle}>{FilterType[x]}</div>
      </div>
  ))}
</div>
}


const allFilters = [
  FilterType.None,
  FilterType.Filter1,
  FilterType.Filter2,
  FilterType.Filter3,
  FilterType.Filter4,
  FilterType.Filter5,
  FilterType.Filter6,
  FilterType.Filter7,
];