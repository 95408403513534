import classNames from 'classnames';

import classes from './BottomMenu.module.scss';
import { BackIcon, SaveIcon } from '../../icons/Icons';
import { useState } from 'react';
import { SliderAdjustBottomMenuItem } from './SliderAdjustBottomMenuItem';
import { FilterType } from '../../types';
import { FilterAdjustBottomMenuItem } from './FilterAdjustBottomMenuItem';

export interface AdjustBottomMenuProps {
  filterType: FilterType;
  brightness: number;
  contrast: number;
  hue: number;
  saturation: number;
  onBack: () => void;
  onSave: () => void;
  onFilterTypeChanged: (filterType: FilterType) => void;
  onBrightnessChanged: (brightness: number) => void;
  onContrastChanged: (contrast: number) => void;
  onHueChanged: (hue: number) => void;
  onSaturationChanged: (saturation: number) => void;
}

export function AdjustBottomMenu(props: AdjustBottomMenuProps) {
  const [selectedMenu, setSelectedMenu] = useState<MenuType>('filters');

  const getMenuItemClasses = (menuType: MenuType) => {
    return classNames(classes.item, menuType === selectedMenu ? classes.selected : null);
  }

  return <div className={`${classes.root} ${classes.largeMenu}`}>
    <div className={classes.titleContainer}>
      <div className={classNames(classes.item, classes.left)} onClick={props.onBack}><BackIcon className={classes.icon}></BackIcon></div>
      <div className={classNames(classes.item, classes.middle)}>Adjust</div>
      <div className={classNames(classes.item, classes.right)} onClick={props.onSave}><SaveIcon className={classes.icon}></SaveIcon></div>
    </div>

    <div className={classes.adjustTitlesContainer}>
      <div onClick={() => setSelectedMenu('filters')} className={getMenuItemClasses('filters')}>Filters</div>
      <div onClick={() => setSelectedMenu('brightness')} className={getMenuItemClasses('brightness')}>Brightness</div>
      <div onClick={() => setSelectedMenu('contrast')} className={getMenuItemClasses('contrast')}>Contrast</div>
      <div onClick={() => setSelectedMenu('hue')} className={getMenuItemClasses('hue')}>Hue</div>
      <div onClick={() => setSelectedMenu('saturation')} className={getMenuItemClasses('saturation')}>Saturation</div>
    </div>
    
    { selectedMenu === 'filters' ? <FilterAdjustBottomMenuItem filterType={props.filterType} onFilterTypeChanged={props.onFilterTypeChanged}></FilterAdjustBottomMenuItem> : null }
    { selectedMenu === 'brightness' ? <SliderAdjustBottomMenuItem min={-100} max={100} value={props.brightness} onValueChanged={props.onBrightnessChanged}></SliderAdjustBottomMenuItem> : null }
    { selectedMenu === 'contrast' ? <SliderAdjustBottomMenuItem min={-100} max={100} value={props.contrast} onValueChanged={props.onContrastChanged}></SliderAdjustBottomMenuItem> : null }
    { selectedMenu === 'hue' ? <SliderAdjustBottomMenuItem min={0} max={360} value={props.hue} onValueChanged={props.onHueChanged}></SliderAdjustBottomMenuItem> : null }
    { selectedMenu === 'saturation' ? <SliderAdjustBottomMenuItem min={-100} value={props.saturation} max={100} onValueChanged={props.onSaturationChanged}></SliderAdjustBottomMenuItem> : null }
  </div>
}

type MenuType = 'filters' | 'brightness' | 'contrast' | 'hue' | 'saturation';

