import { Guid } from 'js-guid';
import { BehaviorSubject, Observable } from 'rxjs';

import { FilterType, Image, RenderType } from '../types';
import backendService from './BackendService';

class ImagesService {
  private images: Image[] = [];
  private prefetched = false;
  private subject = new BehaviorSubject<Image[]>([]);

  public onImages(): Observable<Image[]> {
    return this.subject.asObservable();
  }

  public allImages(): ReadonlyArray<Image> {
    return this.images;
  }

  public imageById(id: string): Image | null {
    const index = this.images.findIndex(x => x.imageId === id);
    if (index !== -1) {
      return this.images[index];
    }
    else {
      return null;
    }
  }

  public addImage(tileBlob: Blob, thumbnailDataUrl: string, renderType: RenderType) {
    const imageId = Guid.newGuid().toString().toUpperCase();

    const image = {
      imageId: imageId,
      date: new Date().toString(),
      renderType: renderType,
      filterType: FilterType.None,
      brightness: 0,
      contrast: 0,
      hue: 0,
      saturation: 0,
      thumbnailDataUrl: thumbnailDataUrl
    }

    this.images = [image, ...this.images];
    this.subject.next(this.images);

    const formData = new FormData();
    formData.append('imageId', imageId);
    formData.append('type', renderType.toString());
    formData.append('tile', tileBlob);

    backendService
      .fetch('/image', { method: 'POST', formBody: formData })
      .then(x => x.json())
      .then(x => x as ImageResult)
      .then(x => ({ ...x, imageId: x.imageId.toLocaleUpperCase() }))
      .then(x => {
        console.log('Image uploaded');
        const index = this.images.findIndex(y => y.imageId === x.imageId);
        if (index !== -1) {
          this.images[index] = x;
          this.images = [...this.images];
          this.subject.next(this.images);
        }
      })
      .catch(x => console.error(x));
  }

  public updateImage(image: Image) {
    backendService
      .fetch(`/image?imageId=${image.imageId}&renderType=${image.renderType}&filterType=${image.filterType}&brightness=${image.brightness}&contrast=${image.contrast}&hue=${image.hue}&saturation=${image.saturation}`, {  method: 'PUT' })
      .then(x => x.json())
      .then(x => x as ImageResult)
      .then(x => ({ ...x, imageId: x.imageId.toLocaleUpperCase() }))
      .then(x => {
        console.log('Image update');
        const index = this.images.findIndex(y => y.imageId === x.imageId);
        if (index !== -1) {
          this.images[index] = x;
          this.images = [...this.images];
          this.subject.next(this.images);
        }
      })
      .catch(x => console.error(x));
  }

  public saveCopyOfImage(image: Image, onSaved: (imageId: string) => void) {
    backendService
      .fetch(`/saveCopyImage?imageId=${image.imageId}&renderType=${image.renderType}&filterType=${image.filterType}&brightness=${image.brightness}&contrast=${image.contrast}&hue=${image.hue}&saturation=${image.saturation}`, {  method: 'POST' })
      .then(x => x.json())
      .then(x => x as ImageResult)
      .then(x => ({ ...x, imageId: x.imageId.toLocaleUpperCase() }))
      .then(x => {
        console.log('Image saved');
        this.images = [x, ...this.images];
        this.subject.next(this.images);
        onSaved(x.imageId);
      })
      .catch(x => console.error(x));
  }

  public deleteImages(images: Image[]) {
    const imageIds = images.filter(x => !x.isVideo).map(x => x.imageId);
    const imagesFormData = new FormData();
    imageIds.forEach((x, i) => imagesFormData.append(`imageIds[${i}]`, x));

    backendService
      .fetch('/image', {  method: 'DELETE', formBody: imagesFormData })
      .then(x => x.json())
      .then(x => x as string[])
      .then(x => x.map(y => y.toLocaleUpperCase()))
      .then(x => {
        console.log('Images deleted');
        x.forEach(id => {
          const index = this.images.findIndex(y => y.imageId === id);
          if (index !== -1) {
            this.images.splice(index, 1);
            this.images = [...this.images];
            this.subject.next(this.images);
          }
        });
      })
      .catch(x => console.error(x));

      const videoIds = images.filter(x => x.isVideo).map(x => x.imageId);
      const videosFormData = new FormData();
      videoIds.forEach((x, i) => videosFormData.append(`imageIds[${i}]`, x));

      backendService
      .fetch('/videos', {  method: 'DELETE', formBody: videosFormData })
      .then(x => x.json())
      .then(x => x as string[])
      .then(x => x.map(y => y.toLocaleUpperCase()))
      .then(x => {
        console.log('Videos deleted');
        x.forEach(id => {
          const index = this.images.findIndex(y => y.imageId === id);
          if (index !== -1) {
            this.images.splice(index, 1);
            this.images = [...this.images];
            this.subject.next(this.images);
          }
        });
      })
      .catch(x => console.error(x));
  }

  public createVideo(tileBlobs: Blob[], renderType: RenderType, thumbnailDataUrl: string) {

    const imageId = Guid.newGuid().toString().toUpperCase();

    const image = {
      imageId: imageId,
      date: new Date().toString(),
      renderType: renderType,
      filterType: FilterType.None,
      brightness: 0,
      contrast: 0,
      hue: 0,
      saturation: 0,
      thumbnailDataUrl: thumbnailDataUrl,
      isVideo: true,
      isRendering: true,
    }

    this.images = [image, ...this.images];
    this.subject.next(this.images);

    const formData = new FormData();
    formData.append('imageId', imageId);
    formData.append('type', renderType.toString());
    for (let i = 0; i < tileBlobs.length; i++) {
      formData.append('tiles', tileBlobs[i]);
    }
    
    backendService
      .fetch('/videos', { method: 'POST', formBody: formData })
      .then(x => x.json())
      .then(x => x as ImageResult)
      .then(x => ({ ...x, imageId: x.imageId.toLocaleUpperCase() }))
      .then(x => {
        console.log('Video created');
        const index = this.images.findIndex(y => y.imageId === x.imageId);
        if (index !== -1) {
          this.images[index] = x;
          this.images = [...this.images];
          this.subject.next(this.images);
        }
      })
      .catch(x => console.error(x));
  }

  public fetchImages() {
    backendService
      .fetch('/images')
      .then(x => x.json())
      .then(x => x as ImagesResult)
      .then(x => x.images.map(y => ({ ...y, imageId: y.imageId.toLocaleUpperCase()})))
      .then(x => {
        console.log(`Images received: ${x.length}`);
        this.images = x;
        this.subject.next(this.images);
      })
      .catch(x => console.error(x));
  }

  public prefetchImages() {
    if (!this.prefetched) {
      this.prefetched = true;
      this.fetchImages();
    }
  }
}

const imagesService = new ImagesService();

export default imagesService;

interface ImageResult {
  imageId: string;

  date: string;

  renderType: RenderType;

  readonly filterType: FilterType;
  readonly brightness: number;
  readonly contrast: number;
  readonly hue: number;
  readonly saturation: number;
}

interface ImagesResult {
  images: ImageResult[];
}
