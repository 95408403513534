import { BehaviorSubject, Observable } from "rxjs";
import backendService from "./BackendService";

export interface Account {
  name: string;
}

export class AccountService {
  private subject = new BehaviorSubject<Account | null>(null);

  public onAccount(): Observable<Account | null> {
    return this.subject.asObservable();
  }

  public createAccount(email: string, password: string) {
    
  }

  public fetchAccount() {
    backendService
      .fetch('/account')
      .then(x => x.json())
      .then(x => x as AccountResult)
      .then(x => {
        console.log('Account received');
        this.subject.next(x);
      });
  }
}


const accountService = new AccountService();

export default accountService;


interface AccountResult {
  name: string;  
}