
import { useRef } from 'react';
import { CloseIcon } from '../../icons/Icons';
import classes from './Dialogs.module.scss';
import classNames from 'classnames';

interface CreateCollectionDialogProps {
    onClose: () => void;
    onCreate: (name: string) => void;
}

export function CreateCollectionDialog(props: CreateCollectionDialogProps) {
    const name = useRef<HTMLInputElement>(null);

    const onKeyDown = (key: string) => {
        if (key === 'Enter') {
            props.onCreate(name.current?.value ?? '');
        }
        else if (key === 'Escape') {
            props.onClose();
        }
    }

    return <div className={classes.root}>
        <div className={classes.dialog}>
            <div className={classes.close}>
                <CloseIcon className={classes.icon} onClick={() => props.onClose()}></CloseIcon>
            </div>

            <div className={classes.title}>Create collection</div>

            <div className={classes.inputContainer}>
                <input className={classNames(classes.input, classes.inputPadding)} type='text' placeholder='Name' autoFocus={true} onKeyDown={x => onKeyDown(x.key)} ref={name}></input>
            </div>

            <div className={classes.inputContainer}>
                <button className={classes.inputButton} onClick={() => props.onCreate(name.current?.value ?? '')}>Save</button>
            </div>
        </div>
    </div>
}
