import { useObservableState } from 'observable-hooks';
import { PlusIcon, SelectableIcon, SelectedIcon } from '../../icons/Icons';
import backendService from '../../services/BackendService';
import collectionsService from '../../services/CollectionsService';
import imagesService from '../../services/ImagesService';
import classes from './Collections.module.scss';
import { useNavigate } from 'react-router';
import routerService from '../../services/RouterService';
import { useLongPress } from 'use-long-press';
import { useState } from 'react';
import { ItemBottomMenu, MenuItem } from '../bottomMenu/ItemBottomMenu';

interface CollectionsProps {
    selectionMode: boolean;
    fullMode: boolean;
    onCreateNewCollection: () => void;
    selectedCollection: string | undefined;
    onCollectionSelected: (collectionId: string | undefined) => void;
}

export function Collections(props: CollectionsProps) {
    const navigate = useNavigate();
    const [inSelectionMode, setInSelectionMode] = useState(false);
    
    const collections = useObservableState(collectionsService.onCollections(), []);
    const images = useObservableState(imagesService.onImages(), []);

    const collectionLongPressBind = useLongPress(() => {
        if ('vibrate' in navigator) navigator.vibrate([100, 100, 100]);
        setInSelectionMode(!inSelectionMode)
      },
      {
        filterEvents: (event) => true,
        threshold: 500,
        captureEvent: true,
        cancelOnMovement: false,
        cancelOutsideElement: true
      });

    const renderCollectionImage = (imageIds: string[]) => {
        if (imageIds.length > 0) {
            const image = images.find(x => x.imageId === imageIds[0]);
            if (image !== undefined) {
                return <img className={classes.circle} src={backendService.getGetImageThumbnailUrl(image.imageId, image.renderType)} alt='pattern'></img>;
            }
            else {
                console.log(`Image not found: ${imageIds[0]}`);
            }
        }

        return <div className={classes.circle}></div>;
    }

    const onCollectionClicked = (collectionId: string) => {
        if (props.selectionMode || inSelectionMode) { 
            if (props.selectedCollection !== collectionId) {
                props.onCollectionSelected(collectionId);
            }
            else {
                props.onCollectionSelected(undefined);
            }
        }
        else {
            navigate(routerService.getCollectionUrl(collectionId));
        }
    }

    const onShare = () => {
        alert('not implemented');
      }
    
      const onDelete = () => {
        if (props.selectedCollection !== undefined) {
            collectionsService.deleteCollection(props.selectedCollection);
        }
        setInSelectionMode(false);
      }

    const menuItems: MenuItem[] =
    [
        {
            title: 'adjust',
            icon: 'adjust',
            onClick: () => { alert('not implemented'); }
        },
        {
            title: 'share',
            icon: 'share',
            onClick: onShare,
        },
        {
            title: 'delete',
            icon: 'delete',
            onClick: onDelete,
        }
    ];

    return <div className={classes.root}>
        <div className={props.fullMode ? classes.fullPreviewContainer : classes.previewContainer}>
            <div className={classes.item} onClick={() => props.onCreateNewCollection()}>
                <div className={classes.circle} >
                    <PlusIcon className={classes.icon}></PlusIcon>
                </div>
                <div className={classes.label}>New</div>
            </div>

            {collections.map(x => {
                return <div {...collectionLongPressBind()} className={classes.item} onClick={() => onCollectionClicked(x.id)} key={x.id}>
                    { props.selectionMode || inSelectionMode
                        ? <div className={classes.iconContainer}>
                            { x.id !== props.selectedCollection 
                                ? <SelectableIcon className={classes.icon}></SelectableIcon>
                                : <SelectedIcon className={classes.icon}></SelectedIcon> }
                        </div>
                        : null }
                    {renderCollectionImage(x.imageIds)}
                    <div className={classes.label}>{x.name}</div>
                </div>
            })}
        </div>

        { inSelectionMode && props.selectedCollection ? <ItemBottomMenu menuItems={menuItems} onBack={() => setInSelectionMode(false)}></ItemBottomMenu> : null }
    </div>
}
