import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import backendService from '../../services/BackendService';
import imagesService from '../../services/ImagesService';
import routerService from '../../services/RouterService';
import { RenderType, RenderTypeCount } from '../../types';
import { useImageSearchParams } from '../../hooks/useImageSearchParams';
import { RepatternBottomMenu } from '../bottomMenu/RepatternBottomMenu';
import classes from './RepatternImagePage.module.scss';
import { LoadingOverload } from '../overlay/LoadingOverload';
import { updateImageParameters } from '../../utils/updateImageParamters';


export function RepatternImagePage() {
  const params = useImageSearchParams();
  const image = imagesService.imageById(params.imageId);
  const navigate = useNavigate();
  const [renderType, setRenderType] = useState(params?.renderType ?? image?.renderType ?? RenderType.Triangles1);
  const [loading, setLoading] = useState(true);

  const swipeableHandlers = useSwipeable({
    onSwipedUp: () => changeRenderType('up'),
    onSwipedDown: () => changeRenderType('down'),
  });

  if (image === null) {
    return <div></div>;
  }

  const imageParameter = updateImageParameters({ ...params, renderType: renderType}, image);

  const changeRenderType = (direction: 'up' | 'down') => {
    let newRenderType = renderType + (direction === 'up' ? 1 : -1);
    if (newRenderType < 0) {
      newRenderType = RenderTypeCount - 1;
    }
    else if (newRenderType >= RenderTypeCount) {
      newRenderType = 0;
    }

    setRenderType(newRenderType as RenderType);
    setLoading(true);
  }

  const onSave = () => {
    navigate(routerService.getFullscreenImageUrlWithImageParameters(imageParameter));
  }

  return <div {...swipeableHandlers} className={classes.page}>
    <div>
      <img onLoad={() => setLoading(false)} className={classes.image} src={backendService.getGetImageRepatternUrl(imageParameter)} alt='pattern'></img>
      { loading ? <img className={classes.image} src={backendService.getGetImageUrl(params.imageId)} alt='pattern'></img> : null }
      { loading ? <LoadingOverload></LoadingOverload> : null}
    </div>

    <RepatternBottomMenu renderType={renderType} onRenderTypeChanged={setRenderType} onBack={() => navigate(routerService.getFullscreenImageUrl(params, 2))} onSave={onSave} ></RepatternBottomMenu>
  </div>
}
