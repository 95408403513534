import { BehaviorSubject, Observable } from "rxjs";

export class AccessTokenService {
    private subject = new BehaviorSubject<string | null>(null);

    constructor() {
        this.subject.next(this.getAccessToken());
    }

    public onAccessToken(): Observable<string | null> {
        return this.subject.asObservable();
    }

    public getAccessToken(): string | null {
        const token = localStorage.getItem('accessToken');

        if (token === null) {
            console.warn('AccessToken is no longer in local storage');
        }

        return token;
    }

    public setAccessToken(token: string) {
        localStorage.setItem('accessToken', token);
        this.subject.next(token);
    }

    public resetAccessToken() {
        localStorage.clear();
        this.subject.next(null);
    }
}

const accessTokenService = new AccessTokenService();

export default accessTokenService;

// export default class AccessTokenService {
//     private static accessToken: string | null = null;

//     public static getAccessToken(): string | null {
//         return AccessTokenService.accessToken;
//     }

//     public static setAccessToken(token: string) {
//         AccessTokenService.accessToken = token;
//     }

//     public static resetAccessToken() {
//         AccessTokenService.accessToken = null;
//     }
// }