import { useState } from "react"

import classes from './BottomMenu.module.scss';

interface SliderAdjustBottomMenuItemProps {  
  min: number,
  max: number,
  value: number,
  onValueChanged: (value: number) => void;
}

export function SliderAdjustBottomMenuItem(props: SliderAdjustBottomMenuItemProps) {
  const [value, setValue] = useState(props.value);

  const onValueChanged = (newValue: number) => {
    props.onValueChanged(newValue);
    setValue(newValue);
  }

  return <div className={classes.adjustSliderContainer}>
    <input type='range' min={props.min} max={props.max} value={value} onChange={x => onValueChanged(x.target.valueAsNumber)} className={classes.slider}></input>
  </div>
}