import { BehaviorSubject, Observable } from "rxjs";
import { Collection } from "../types";
import backendService from "./BackendService";
import imagesService from "./ImagesService";

class CollectionsService {
    private collections: Collection[] = [];
    private prefetched = false;
    private subject = new BehaviorSubject<Collection[]>([]);

    public onCollections(): Observable<Collection[]> {
        return this.subject.asObservable();
    }
    
    public allCollections(): ReadonlyArray<Collection> {
        return this.collections;
    }

    public collectionById(id: string | null): Collection | null {
      const index = this.collections.findIndex(x => x.id === id);
      if (index !== -1) {
        return this.collections[index];
      }
      else {
        return null;
      }
    }

    public createCollection(collectionName: string, copyImages: boolean, imageIds: string[] | null = null) {
      const formData = new FormData();
      formData.append('collectionName', collectionName);
      formData.append('copyImages', copyImages ? 'true' : 'false');
      imageIds?.forEach((x, i) => formData.append(`imageIds[${i}]`, x));

      backendService
        .fetch('/collections', { method: 'POST', formBody: formData })
        .then(x => x.json())
        .then(x => x as Collection)
        .then(x => ({ ...x, imageIds: x.imageIds.map(y => y.toLocaleUpperCase()) }))
        .then(x => {
          console.log('Collection created');
          this.collections = [x, ...this.collections];
          this.subject.next(this.collections);

          imagesService.fetchImages();
        })
        .catch(x => console.error(x));
    }

    public addImages(collectionId: string, copyImages: boolean, imageIds: string[]) {
      const formData = new FormData();
      formData.append('collectionId', collectionId);
      formData.append('copyImages', copyImages ? 'true' : 'false');
      imageIds.forEach((x, i) => formData.append(`imageIds[${i}]`, x));

      backendService
        .fetch('/collections', { method: 'PUT', formBody: formData })
        .then(x => x.json())
        .then(x => x as Collection)
        .then(x => ({ ...x, imageIds: x.imageIds.map(y => y.toLocaleUpperCase()) }))
        .then(x => {
          console.log('Images added to collection');
          const index = this.collections.findIndex(y => y.id === x.id);
          if (index !== -1) {
            this.collections[index] = x;
            this.collections = [...this.collections];
            this.subject.next(this.collections);
          }

          imagesService.fetchImages();
        })
        .catch(x => console.error(x));
    }

    public deleteCollection(collectionId: string) {
      backendService
        .fetch(`/collections?collectionId=${collectionId}`, { method: 'DELETE' })
        .then(x => x.json())
        .then(x => x as Collection)
        .then(x => {
          console.log('Collection deleted');

          const index = this.collections.findIndex(y => y.id === x.id);
          if (index !== -1) {
            this.collections.splice(index, 1);
            this.collections = [...this.collections];
            this.subject.next(this.collections);
          }

          imagesService.fetchImages();
        })
        .catch(x => console.error(x));
    }

    public fetchCollections() {
        backendService
          .fetch('/collections')
          .then(x => x.json())
          .then(x => x as CollectionsResult)
          .then(x => x.collections.map(y => ({ ...y, imageIds: y.imageIds.map(z => z.toLocaleUpperCase())})))
          .then(x => {
            console.log(`Collections received: ${x.length}`);
            this.collections = x;
            this.subject.next(this.collections);
          })
          .catch(x => console.error(x));
      }
    
      public prefetchCollections() {
        if (!this.prefetched) {
          this.prefetched = true;
          this.fetchCollections();
        }
      }
}

const collectionsService = new CollectionsService();

export default collectionsService;

interface CollectionsResult {
    readonly collections: Collection[];
}