import { ImageParameter, ImageParameterOptionals } from "../types";

export function updateImageParameters(imageParameters: ImageParameterOptionals, image: ImageParameter): ImageParameter {
    return {
        imageId: imageParameters.imageId,
        renderType: imageParameters.renderType ?? image.renderType,
        filterType: imageParameters.filterType ?? image.filterType,
        brightness: imageParameters.brightness ?? image.brightness,
        contrast: imageParameters.contrast ?? image.contrast,
        hue: imageParameters.hue ?? image.hue,
        saturation: imageParameters.saturation ?? image.saturation
    };
}