
import { CloseIcon } from '../../icons/Icons';
import classes from './Dialogs.module.scss';

interface CopyOrMoveCollectionDialogProps {
    onSelection: (type: 'copy' | 'move' | 'close') => void;
}

export function CopyOrMoveCollectionDialog(props: CopyOrMoveCollectionDialogProps) {
    return <div className={classes.root}>
        <div className={classes.dialog}>
            <div className={classes.close}>
                <CloseIcon className={classes.icon} onClick={() => props.onSelection('close')}></CloseIcon>
            </div>

            <div className={classes.title}>Add to collection</div>

            <div className={classes.inputContainer}>
                <button className={classes.inputButtonNormal} onClick={() => props.onSelection('copy')}>Copy</button>
            </div>

            <div className={classes.inputContainer}>
                <button className={classes.inputButtonNormal} onClick={() => props.onSelection('move')}>Move</button>
            </div>
        </div>
    </div>
}
