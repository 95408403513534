import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import accessTokenService from "./services/AccessTokenService";
import accountService from "./services/AccountService";
import backendService from "./services/BackendService";
import imagesService from "./services/ImagesService";
import collectionsService from "./services/CollectionsService";

let lastAccessToken: string | null = null;

export default function Root() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() =>{
    if (location.pathname === '/') {
      navigate('/splash');  
    }
    else if (location.pathname !== '/splash' &&
             location.pathname !== '/createaccount' &&
             location.pathname !== '/createemailaccount' &&
             location.pathname !== '/emaillogin') {

      const backendSubscription = backendService
        .onState()
        .subscribe(x => {
          if (x === 'AccessDenied') {
            navigate('/createaccount');  
          }
        })
      
      let forceFetchImages = false;
      const accessTokenSubscription = accessTokenService.onAccessToken().subscribe(x => {
        forceFetchImages = lastAccessToken !== x;
        lastAccessToken = x;

        if (x === null) {
          navigate('/createaccount');
        } else {          
          backendService
            .fetch(`/validateToken?accessToken=${x}`, { secure: false })
            .then(x => {
              console.log('Access token validated by backend');
              
              if (forceFetchImages) {
                console.log('Force fetching images and collections');
                imagesService.fetchImages();
                collectionsService.fetchCollections();
              } else {
                imagesService.prefetchImages();
                collectionsService.prefetchCollections();
              }

              accountService.fetchAccount();
            })
            .catch(x => {
              console.log("Access token is invalid");
              accessTokenService.resetAccessToken();
            });
        }
      })

      return () => { 
        backendSubscription.unsubscribe();
        accessTokenSubscription.unsubscribe();
      }
    }
  })

  return <div>
    <Outlet />
  </div>
}