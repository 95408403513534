import { RenderType } from "../../types";
import { createIndices, createIndicesXY, createTextureCoordinates, createTextureCoordinatesXY } from "./utils";

interface Geometry {
    positions: number[];
    textureCoordinates: number[];
    indices: number[];
};

export function createGeometry(renderType: RenderType): Geometry {
    switch (renderType) {
        case RenderType.Triangles1:
            return {
                positions: createTriangle1Positions(),
                textureCoordinates: createTriangle1TextureCoordinates(),
                indices: createTriangle1Indices(),
            };

        case RenderType.Triangles2:
            return {
                positions: createTriangle2Positions(),
                textureCoordinates: createTriangle2TextureCoordinates(),
                indices: createTriangle2Indices(),
            };

        case RenderType.Triangles3:
            return {
                positions: createTriangle3Positions(),
                textureCoordinates: createTriangle3TextureCoordinates(),
                indices: createTriangle3Indices(),
            };

        case RenderType.Triangles4:
            return {
                positions: createTriangle4Positions(),
                textureCoordinates: createTriangle4TextureCoordinates(),
                indices: createTriangle4Indices(),
            };

        case RenderType.Triangles5:
            return {
                positions: createTriangle5Positions(),
                textureCoordinates: createTriangle5TextureCoordinates(),
                indices: createTriangle5Indices(),
            };

        case RenderType.Triangles6:
            return {
                positions: createTriangle6Positions(),
                textureCoordinates: createTriangle6TextureCoordinates(),
                indices: createTriangle6Indices(),
            };

        case RenderType.Triangles7:
            return {
                positions: createTriangle7Positions(),
                textureCoordinates: createTriangle7TextureCoordinates(),
                indices: createTriangle7Indices(),
            };

        case RenderType.Triangles8:
            return {
                positions: createTriangle8Positions(),
                textureCoordinates: createTriangle8TextureCoordinates(),
                indices: createTriangle8Indices(),
            };

        case RenderType.Triangles9:
            return {
                positions: createTriangle9Positions(),
                textureCoordinates: createTriangle9TextureCoordinates(),
                indices: createTriangle9Indices(),
            };

        case RenderType.Triangles10:
            return {
                positions: createTriangle10Positions(),
                textureCoordinates: createTriangle10TextureCoordinates(),
                indices: createTriangle10Indices(),
            };

        case RenderType.Triangles11:
            return {
                positions: createTriangle11Positions(),
                textureCoordinates: createTriangle11TextureCoordinates(),
                indices: createTriangle11Indices(),
            };

        case RenderType.Triangles12:
            return {
                positions: createTriangle12Positions(),
                textureCoordinates: createTriangle12TextureCoordinates(),
                indices: createTriangle12Indices(),
            };

        case RenderType.Triangles13:
            return {
                positions: createTriangle13Positions(),
                textureCoordinates: createTriangle13TextureCoordinates(),
                indices: createTriangle13Indices(),
            };

        case RenderType.Triangles14:
            return {
                positions: createTriangle14Positions(),
                textureCoordinates: createTriangle14TextureCoordinates(),
                indices: createTriangle14Indices(),
            };

        case RenderType.Triangles15:
            return {
                positions: createTriangle15Positions(),
                textureCoordinates: createTriangle15TextureCoordinates(),
                indices: createTriangle15Indices(),
            };

        case RenderType.Triangles16:
            return {
                positions: createTriangle16Positions(),
                textureCoordinates: createTriangle16TextureCoordinates(),
                indices: createTriangle16Indices(),
            };

        case RenderType.Triangles17:
            return {
                positions: createTriangle17Positions(),
                textureCoordinates: createTriangle17TextureCoordinates(),
                indices: createTriangle17Indices(),
            };

        case RenderType.Squares1:
            return {
                positions: createSquare1Positions(),
                textureCoordinates: createSquare1TextureCoordinates(),
                indices: createSquare1Indices(),
            };
        
        case RenderType.Squares2:
            return {
                positions: createSquare2Positions(),
                textureCoordinates: createSquare2TextureCoordinates(),
                indices: createSquare2Indices(),
            };

        case RenderType.Squares3:
            return {
                positions: createSquare3Positions(),
                textureCoordinates: createSquare3TextureCoordinates(),
                indices: createSquare3Indices(),
            };

        case RenderType.Squares4:
            return {
                positions: createSquare4Positions(),
                textureCoordinates: createSquare4TextureCoordinates(),
                indices: createSquare4Indices(),
            };

        case RenderType.Squares5:
            return {
                positions: createSquare5Positions(),
                textureCoordinates: createSquare5TextureCoordinates(),
                indices: createSquare5Indices(),
            };

        case RenderType.Squares6:
            return {
                positions: createSquare6Positions(),
                textureCoordinates: createSquare6TextureCoordinates(),
                indices: createSquare6Indices(),
            };

        case RenderType.Squares7:
            return {
                positions: createSquare7Positions(),
                textureCoordinates: createSquare7TextureCoordinates(),
                indices: createSquare7Indices(),
            };

        case RenderType.Squares8:
            return {
                positions: createSquare8Positions(),
                textureCoordinates: createSquare8TextureCoordinates(),
                indices: createSquare8Indices(),
            };

        case RenderType.Squares9:
            return {
                positions: createSquare9Positions(),
                textureCoordinates: createSquare9TextureCoordinates(),
                indices: createSquare9Indices(),
            };

        case RenderType.Squares10:
            return {
                positions: createSquare10Positions(),
                textureCoordinates: createSquare10TextureCoordinates(),
                indices: createSquare10Indices(),
            };

        case RenderType.Squares11:
            return {
                positions: createSquare11Positions(),
                textureCoordinates: createSquare11TextureCoordinates(),
                indices: createSquare11Indices(),
            };

        case RenderType.Squares12:
            return {
                positions: createSquare12Positions(),
                textureCoordinates: createSquare12TextureCoordinates(),
                indices: createSquare12Indices(),
            };

        case RenderType.Rhombuses1:
            return {
                positions: createRhombuses1Positions(),
                textureCoordinates: createRhombuses1TextureCoordinates(),
                indices: createRhombuses1Indices(),
            };

        case RenderType.Rhombuses2:
            return {
                positions: createRhombuses2Positions(),
                textureCoordinates: createRhombuses2TextureCoordinates(),
                indices: createRhombuses2Indices(),
            };

        case RenderType.Rhombuses3:
            return {
                positions: createRhombuses3Positions(),
                textureCoordinates: createRhombuses3TextureCoordinates(),
                indices: createRhombuses3Indices(),
            };

        case RenderType.Rhombuses4:
            return {
                positions: createRhombuses4Positions(),
                textureCoordinates: createRhombuses4TextureCoordinates(),
                indices: createRhombuses4Indices(),
            };

        case RenderType.Rhombuses5:
            return {
                positions: createRhombuses5Positions(),
                textureCoordinates: createRhombuses5TextureCoordinates(),
                indices: createRhombuses5Indices(),
            };

        case RenderType.Rhombuses6:
            return {
                positions: createRhombuses6Positions(),
                textureCoordinates: createRhombuses6TextureCoordinates(),
                indices: createRhombuses6Indices(),
            };

        case RenderType.Rhombuses7:
            return {
                positions: createRhombuses7Positions(),
                textureCoordinates: createRhombuses7TextureCoordinates(),
                indices: createRhombuses7Indices(),
            };

        case RenderType.Rhombuses8:
            return {
                positions: createRhombuses8Positions(),
                textureCoordinates: createRhombuses8TextureCoordinates(),
                indices: createRhombuses8Indices(),
            };

        case RenderType.Rhombuses9:
            return {
                positions: createRhombuses9Positions(),
                textureCoordinates: createRhombuses9TextureCoordinates(),
                indices: createRhombuses9Indices(),
            };

        case RenderType.Rhombuses10:
            return {
                positions: createRhombuses10Positions(),
                textureCoordinates: createRhombuses10TextureCoordinates(),
                indices: createRhombuses10Indices(),
            };
    }
    
    return {
        positions: createTriangle1Positions(),
        textureCoordinates: createTriangle1TextureCoordinates(),
        indices: createTriangle1Indices(),
    };
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle1
//////////////////////////////////////////////////////////////////////////////////

function createTriangle1Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0, 1.0,   0.5, tri, 1.0,   1.0, 0.0, 1.0,   
        1.0, 0.0, 1.0,   0.5, tri, 1.0,   1.5, tri, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y * tri);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle1TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,   
    ];

    return createTextureCoordinates(template);
}

function createTriangle1Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
    ];

    return createIndices(template);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle2
//////////////////////////////////////////////////////////////////////////////////

function createTriangle2Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0, 1.0,   0.5, tri, 1.0,   1.0, 0.0, 1.0,   
        1.0, 0.0, 1.0,   0.5, tri, 1.0,   1.5, tri, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y * tri);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle2TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
    ];

    return createTextureCoordinates(template);
}

function createTriangle2Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
    ];

    return createIndices(template);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle3
//////////////////////////////////////////////////////////////////////////////////

function createTriangle3Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.0, tri, 1.0,
        1.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.5, tri * 2, 1.0,

        0.5, 0.0, 1.0,   0.0, tri, 1.0,   1.0, tri, 1.0,
        0.5, 0.0, 1.0,   1.0, tri, 1.0,   1.5, 0.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y * tri * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle3TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
    ];

    return createTextureCoordinates(template);
}

function createTriangle3Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndices(template);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle4
//////////////////////////////////////////////////////////////////////////////////

function createTriangle4Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.0, tri, 1.0,
        1.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.5, tri * 2, 1.0,
        1.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.0, tri, 1.0,

        0.5, 0.0, 1.0,   0.0, tri, 1.0,   1.0, tri, 1.0,
        0.5, 0.0, 1.0,   1.0, tri, 1.0,   1.5, 0.0, 1.0,
        1.5, 0.0, 1.0,   1.0, tri, 1.0,   2.0, tri, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 48; y++) {
        for (let x = 0; x < 48; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 3 + (y % 2) * 1.5;
                const yOffset = y * tri;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle4TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 48, 48);
}

function createTriangle4Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
    ];

    return createIndicesXY(template, 48, 48);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle5
//////////////////////////////////////////////////////////////////////////////////

function createTriangle5Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.0, tri, 1.0,
        1.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.5, tri * 2, 1.0,
        1.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.0, tri, 1.0,
        2.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.5, tri * 2, 1.0,

        0.5, 0.0, 1.0,   0.0, tri, 1.0,   1.0, tri, 1.0,
        0.5, 0.0, 1.0,   1.0, tri, 1.0,   1.5, 0.0, 1.0,
        1.5, 0.0, 1.0,   1.0, tri, 1.0,   2.0, tri, 1.0,
        1.5, 0.0, 1.0,   2.0, tri, 1.0,   2.5, 0.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 48; y++) {
        for (let x = 0; x < 48; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y * tri * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle5TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 48, 48);
}

function createTriangle5Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 48, 48);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle6
//////////////////////////////////////////////////////////////////////////////////

function createTriangle6Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.0, tri, 1.0,
        1.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.5, tri * 2, 1.0,

        0.5, 0.0, 1.0,   0.0, tri, 1.0,   1.0, tri, 1.0,
        0.5, 0.0, 1.0,   1.0, tri, 1.0,   1.5, 0.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y * tri * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle6TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
    ];

    return createTextureCoordinates(template);
}

function createTriangle6Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndices(template);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle7
//////////////////////////////////////////////////////////////////////////////////

function createTriangle7Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.5, tri, 1.0,   0.0, tri * 2, 1.0,   1.0, tri * 2, 1.0,
        0.5, tri, 1.0,   1.0, tri * 2, 1.0,   1.5, tri, 1.0,

        0.0, 0.0, 1.0,   0.5, tri, 1.0,   1.0, 0.0, 1.0,
        1.0, 0.0, 1.0,   0.5, tri, 1.0,   1.5, tri, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y * tri * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle7TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinates(template);
}

function createTriangle7Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndices(template);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle8
//////////////////////////////////////////////////////////////////////////////////

function createTriangle8Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.5, 1.0,   0.0, 1.5, 1.0,   tri, 1.0, 1.0,
        tri, 0.5, 1.0,   tri, 1.5, 1.0,   tri * 2, 1.0, 1.0,

        0.0, 0.5, 1.0,   tri, 1.0, 1.0,   tri, 0.0, 1.0,
        tri, 0.5, 1.0,   tri * 2, 1.0, 1.0,   tri * 2, 0.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * tri);
                positions.push(template[i + 1] + y);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle8TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinates(template);
}

function createTriangle8Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndices(template);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle9
//////////////////////////////////////////////////////////////////////////////////

function createTriangle9Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 1.0, 1.0,   0.0, 2.0, 1.0,   tri, 1.5, 1.0,
        0.0, 1.0, 1.0,   tri, 1.5, 1.0,   tri, 0.5, 1.0,

        tri, 1.5, 1.0,   tri, 0.5, 1.0,   tri * 2, 1.0, 1.0,
        tri, 0.5, 1.0,   tri * 2, 1.0, 1.0,   tri * 2, 0.0, 1.0,

        tri * 2, 0.0, 1.0,   tri * 2, 1.0, 1.0,   tri * 3, 0.5, 1.0,
        tri * 2, 1.0, 1.0,   tri * 3, 1.5, 1.0,   tri * 3, 0.5, 1.0,

        tri * 3, 0.5, 1.0,   tri * 3, 1.5, 1.0,   tri * 4, 1.0, 1.0,
        tri * 3, 1.5, 1.0,   tri * 4, 2.0, 1.0,   tri * 4, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 48; y++) {
        for (let x = 0; x < 48; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * tri * 4);
                positions.push(template[i + 1] + y);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle9TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 48, 48);
}

function createTriangle9Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23
    ];

    return createIndicesXY(template, 48, 48);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle10
//////////////////////////////////////////////////////////////////////////////////

function createTriangle10Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.5, 1.0,   tri, 1.0, 1.0,   tri, 0.0, 1.0,
        0.0, 0.5, 1.0,   0.0, 1.5, 1.0,   tri, 1.0, 1.0,

        0.0, 1.5, 1.0,   tri, 2.0, 1.0,   tri, 1.0, 1.0,
        0.0, 1.5, 1.0,   0.0, 2.5, 1.0,   tri, 2.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * tri);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle10TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
    ];

    return createTextureCoordinates(template);
}

function createTriangle10Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndices(template);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle11
//////////////////////////////////////////////////////////////////////////////////

function createTriangle11Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.5, 1.0,   tri, 1.0, 1.0,   tri, 0.0, 1.0,
        0.0, 0.5, 1.0,   0.0, 1.5, 1.0,   tri, 1.0, 1.0,

        0.0, 1.5, 1.0,   tri, 2.0, 1.0,   tri, 1.0, 1.0,
        0.0, 1.5, 1.0,   0.0, 2.5, 1.0,   tri, 2.0, 1.0,

        tri, 0.5, 1.0,   tri * 2, 1.0, 1.0,   tri * 2, 0.0, 1.0,
        tri, 0.5, 1.0,   tri, 1.5, 1.0,   tri * 2, 1.0, 1.0,

        tri, 1.5, 1.0,   tri * 2, 2.0, 1.0,   tri * 2, 1.0, 1.0,
        tri, 1.5, 1.0,   tri, 2.5, 1.0,   tri * 2, 2.0, 1.0,

        tri * 2, 0, 1.0,   tri * 2, 1.0, 1.0,   tri * 3, 0.5, 1.0,
        tri * 2, 1.0, 1.0,   tri * 3, 1.5, 1.0,   tri * 3, 0.5, 1.0,

        tri * 2, 1.0, 1.0,   tri * 2, 2.0, 1.0,   tri * 3, 1.5, 1.0,
        tri * 2, 2.0, 1.0,   tri * 3, 2.5, 1.0,   tri * 3, 1.5, 1.0,

        tri * 3, 0, 1.0,   tri * 3, 1.0, 1.0,   tri * 4, 0.5, 1.0,
        tri * 3, 1.0, 1.0,   tri * 4, 1.5, 1.0,   tri * 4, 0.5, 1.0,

        tri * 3, 1.0, 1.0,   tri * 3, 2.0, 1.0,   tri * 4, 1.5, 1.0,
        tri * 3, 2.0, 1.0,   tri * 4, 2.5, 1.0,   tri * 4, 1.5, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 24; y++) {
        for (let x = 0; x < 48; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * tri * 4);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle11TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
    ];

    return createTextureCoordinatesXY(template, 24, 48);
}

function createTriangle11Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
        24, 25, 26,   27, 28, 29,
        30, 31, 32,   33, 34, 35,
        36, 37, 38,   39, 40, 41,
        42, 43, 44,   45, 46, 47,
    ];

    return createIndicesXY(template, 24, 48);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle12
//////////////////////////////////////////////////////////////////////////////////

function createTriangle12Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.5, 0.0, 1.0,   0.0, tri, 1.0,   1.0, tri, 1.0,
        0.5, 0.0, 1.0,   1.0, tri, 1.0,   1.5, 0.0, 1.0,

        1.5, 0.0, 1.0,   1.0, tri, 1.0,   2.0, tri, 1.0,
        1.5, 0.0, 1.0,   2.0, tri, 1.0,   2.5, 0.0, 1.0,

        2.5, 0.0, 1.0,   2.0, tri, 1.0,   3.0, tri, 1.0,
        2.5, 0.0, 1.0,   3.0, tri, 1.0,   3.5, 0.0, 1.0,

        3.5, 0.0, 1.0,   3.0, tri, 1.0,   4.0, tri, 1.0,
        3.5, 0.0, 1.0,   4.0, tri, 1.0,   4.5, 0.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 48; y++) {
        for (let x = 0; x < 48; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 4);
                positions.push(template[i + 1] + y * tri);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle12TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 48, 48);
}

function createTriangle12Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 48, 48);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle13
//////////////////////////////////////////////////////////////////////////////////

function createTriangle13Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.0, tri, 1.0,
        1.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.5, tri * 2, 1.0,
        1.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.0, tri, 1.0,
        2.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.5, tri * 2, 1.0,
        2.0, tri, 1.0,   2.5, tri * 2, 1.0,   3.0, tri, 1.0,
        3.0, tri, 1.0,   2.5, tri * 2, 1.0,   3.5, tri * 2, 1.0,
        3.0, tri, 1.0,   3.5, tri * 2, 1.0,   4.0, tri, 1.0,

        0.5, 0.0, 1.0,   0.0, tri, 1.0,   1.0, tri, 1.0,
        0.5, 0.0, 1.0,   1.0, tri, 1.0,   1.5, 0.0, 1.0,
        1.5, 0.0, 1.0,   1.0, tri, 1.0,   2.0, tri, 1.0,
        1.5, 0.0, 1.0,   2.0, tri, 1.0,   2.5, 0.0, 1.0,
        2.5, 0.0, 1.0,   2.0, tri, 1.0,   3.0, tri, 1.0,
        2.5, 0.0, 1.0,   3.0, tri, 1.0,   3.5, 0.0, 1.0,
        3.5, 0.0, 1.0,   3.0, tri, 1.0,   4.0, tri, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 48; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 3.5;
                const yOffset = y * 2 * tri + (x % 2) * tri;

                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle13TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,   
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 32, 48);
}

function createTriangle13Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
        24, 25, 26,   27, 28, 29,
        30, 31, 32,   33, 34, 35,
        36, 37, 38,   39, 40, 41,
    ];

    return createIndicesXY(template, 32, 48);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle14
//////////////////////////////////////////////////////////////////////////////////

function createTriangle14Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.0, tri, 1.0,
        1.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.5, tri * 2, 1.0,
        1.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.0, tri, 1.0,
        2.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.5, tri * 2, 1.0,
        2.0, tri, 1.0,   2.5, tri * 2, 1.0,   3.0, tri, 1.0,
        3.0, tri, 1.0,   2.5, tri * 2, 1.0,   3.5, tri * 2, 1.0,
        3.0, tri, 1.0,   3.5, tri * 2, 1.0,   4.0, tri, 1.0,

        0.5, 0.0, 1.0,   0.0, tri, 1.0,   1.0, tri, 1.0,
        0.5, 0.0, 1.0,   1.0, tri, 1.0,   1.5, 0.0, 1.0,
        1.5, 0.0, 1.0,   1.0, tri, 1.0,   2.0, tri, 1.0,
        1.5, 0.0, 1.0,   2.0, tri, 1.0,   2.5, 0.0, 1.0,
        2.5, 0.0, 1.0,   2.0, tri, 1.0,   3.0, tri, 1.0,
        2.5, 0.0, 1.0,   3.0, tri, 1.0,   3.5, 0.0, 1.0,
        3.5, 0.0, 1.0,   3.0, tri, 1.0,   4.0, tri, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 48; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 3.5;
                const yOffset = y * 2 * tri + (x % 2) * tri;

                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle14TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
    ];

    return createTextureCoordinatesXY(template, 32, 48);
}

function createTriangle14Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
        24, 25, 26,   27, 28, 29,
        30, 31, 32,   33, 34, 35,
        36, 37, 38,   39, 40, 41,
    ];

    return createIndicesXY(template, 32, 48);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle15
//////////////////////////////////////////////////////////////////////////////////

function createTriangle15Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.0, tri, 1.0,
        1.0, tri, 1.0,   0.5, tri * 2, 1.0,   1.5, tri * 2, 1.0,
        1.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.0, tri, 1.0,
        2.0, tri, 1.0,   1.5, tri * 2, 1.0,   2.5, tri * 2, 1.0,
        2.0, tri, 1.0,   2.5, tri * 2, 1.0,   3.0, tri, 1.0,
        3.0, tri, 1.0,   2.5, tri * 2, 1.0,   3.5, tri * 2, 1.0,

        0.5, 0.0, 1.0,   0.0, tri, 1.0,   1.0, tri, 1.0,
        0.5, 0.0, 1.0,   1.0, tri, 1.0,   1.5, 0.0, 1.0,
        1.5, 0.0, 1.0,   1.0, tri, 1.0,   2.0, tri, 1.0,
        1.5, 0.0, 1.0,   2.0, tri, 1.0,   2.5, 0.0, 1.0,
        2.5, 0.0, 1.0,   2.0, tri, 1.0,   3.0, tri, 1.0,
        2.5, 0.0, 1.0,   3.0, tri, 1.0,   3.5, 0.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 48; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 3;
                const yOffset = y * 2 * tri;

                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle15TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 32, 48);
}

function createTriangle15Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
        24, 25, 26,   27, 28, 29,
        30, 31, 32,   33, 34, 35,
    ];

    return createIndicesXY(template, 32, 48);
}


//////////////////////////////////////////////////////////////////////////////////
//// Triangle16
//////////////////////////////////////////////////////////////////////////////////

function createTriangle16Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        tri * 1, 0.5, 1.0,   tri * 2, 1.0, 1.0,   tri * 2, 0.0, 1.0,
        tri * 2, 0.0, 1.0,   tri * 2, 1.0, 1.0,   tri * 3, 0.5, 1.0,

        tri * 0, 1.0, 1.0,   tri * 1, 1.5, 1.0,   tri * 1, 0.5, 1.0,
        tri * 1, 0.5, 1.0,   tri * 1, 1.5, 1.0,   tri * 2, 1.0, 1.0,
        tri * 2, 1.0, 1.0,   tri * 3, 1.5, 1.0,   tri * 3, 0.5, 1.0,
        tri * 3, 0.5, 1.0,   tri * 3, 1.5, 1.0,   tri * 4, 1.0, 1.0,

        tri * 0, 1.0, 1.0,   tri * 0, 2.0, 1.0,   tri * 1, 1.5, 1.0,
        tri * 1, 1.5, 1.0,   tri * 2, 2.0, 1.0,   tri * 2, 1.0, 1.0,
        tri * 2, 1.0, 1.0,   tri * 2, 2.0, 1.0,   tri * 3, 1.5, 1.0,
        tri * 3, 1.5, 1.0,   tri * 4, 2.0, 1.0,   tri * 4, 1.0, 1.0,

        tri * 0, 2.0, 1.0,   tri * 1, 2.5, 1.0,   tri * 1, 1.5, 1.0,
        tri * 1, 1.5, 1.0,   tri * 1, 2.5, 1.0,   tri * 2, 2.0, 1.0,
        tri * 2, 2.0, 1.0,   tri * 3, 2.5, 1.0,   tri * 3, 1.5, 1.0,
        tri * 3, 1.5, 1.0,   tri * 3, 2.5, 1.0,   tri * 4, 2.0, 1.0,

        tri * 0, 2.0, 1.0,   tri * 0, 3.0, 1.0,   tri * 1, 2.5, 1.0,
        tri * 1, 2.5, 1.0,   tri * 2, 3.0, 1.0,   tri * 2, 2.0, 1.0,
        tri * 2, 2.0, 1.0,   tri * 2, 3.0, 1.0,   tri * 3, 2.5, 1.0,
        tri * 3, 2.5, 1.0,   tri * 4, 3.0, 1.0,   tri * 4, 2.0, 1.0,

        tri * 0, 3.0, 1.0,   tri * 1, 3.5, 1.0,   tri * 1, 2.5, 1.0,
        tri * 1, 2.5, 1.0,   tri * 1, 3.5, 1.0,   tri * 2, 3.0, 1.0,
        tri * 2, 3.0, 1.0,   tri * 3, 3.5, 1.0,   tri * 3, 2.5, 1.0,
        tri * 3, 2.5, 1.0,   tri * 3, 3.5, 1.0,   tri * 4, 3.0, 1.0,

        tri * 0, 3.0, 1.0,   tri * 0, 4.0, 1.0,   tri * 1, 3.5, 1.0,
        tri * 1, 3.5, 1.0,   tri * 2, 4.0, 1.0,   tri * 2, 3.0, 1.0,
        tri * 2, 3.0, 1.0,   tri * 2, 4.0, 1.0,   tri * 3, 3.5, 1.0,
        tri * 3, 3.5, 1.0,   tri * 4, 4.0, 1.0,   tri * 4, 3.0, 1.0,

        tri * 0, 4.0, 1.0,   tri * 1, 4.5, 1.0,   tri * 1, 3.5, 1.0,
        tri * 1, 3.5, 1.0,   tri * 1, 4.5, 1.0,   tri * 2, 4.0, 1.0,
        tri * 2, 4.0, 1.0,   tri * 3, 4.5, 1.0,   tri * 3, 3.5, 1.0,
        tri * 3, 3.5, 1.0,   tri * 3, 4.5, 1.0,   tri * 4, 4.0, 1.0,

        tri * 1, 4.5, 1.0,   tri * 2, 5.0, 1.0,   tri * 2, 4.0, 1.0,
        tri * 2, 4.0, 1.0,   tri * 2, 5.0, 1.0,   tri * 3, 4.5, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 16; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * tri + (y % 2) * 2 * tri;
                const yOffset = y * 4;

                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle16TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
    ];

    return createTextureCoordinatesXY(template, 32, 16);
}

function createTriangle16Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
        24, 25, 26,   27, 28, 29,
        30, 31, 32,   33, 34, 35,
        36, 37, 38,   39, 40, 41,
        42, 43, 44,   45, 46, 47,
        48, 49, 50,   51, 52, 53,
        54, 55, 56,   57, 58, 59,
        60, 61, 62,   63, 64, 65,
        66, 67, 68,   69, 70, 71,
        72, 73, 74,   75, 76, 77,
        78, 79, 80,   81, 82, 83,
        84, 85, 86,   87, 88, 89,
        90, 91, 92,   93, 94, 95,
    ];

    return createIndicesXY(template, 32, 16);
}

//////////////////////////////////////////////////////////////////////////////////
//// Triangle17
//////////////////////////////////////////////////////////////////////////////////

function createTriangle17Positions() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        1.0, tri * 0, 1.0,   0.5, tri * 1, 1.0,   1.5, tri * 1, 1.0,
        1.0, tri * 0, 1.0,   1.5, tri * 1, 1.0,   2.0, tri * 0, 1.0,
        2.0, tri * 0, 1.0,   1.5, tri * 1, 1.0,   2.5, tri * 1, 1.0,
        2.0, tri * 0, 1.0,   2.5, tri * 1, 1.0,   3.0, tri * 0, 1.0,
        3.0, tri * 0, 1.0,   2.5, tri * 1, 1.0,   3.5, tri * 1, 1.0,
        3.0, tri * 0, 1.0,   3.5, tri * 1, 1.0,   4.0, tri * 0, 1.0,
        4.0, tri * 0, 1.0,   3.5, tri * 1, 1.0,   4.5, tri * 1, 1.0,

        0.5, tri * 1, 1.0,   0.0, tri * 2, 1.0,   1.0, tri * 2, 1.0,
        0.5, tri * 1, 1.0,   1.0, tri * 2, 1.0,   1.5, tri * 1, 1.0,
        1.5, tri * 1, 1.0,   1.0, tri * 2, 1.0,   2.0, tri * 2, 1.0,
        1.5, tri * 1, 1.0,   2.0, tri * 2, 1.0,   2.5, tri * 1, 1.0,
        2.5, tri * 1, 1.0,   2.0, tri * 2, 1.0,   3.0, tri * 2, 1.0,
        2.5, tri * 1, 1.0,   3.0, tri * 2, 1.0,   3.5, tri * 1, 1.0,
        3.5, tri * 1, 1.0,   3.0, tri * 2, 1.0,   4.0, tri * 2, 1.0,
        3.5, tri * 1, 1.0,   4.0, tri * 2, 1.0,   4.5, tri * 1, 1.0,
        4.5, tri * 1, 1.0,   4.0, tri * 2, 1.0,   5.0, tri * 2, 1.0,

        0.0, tri * 2, 1.0,   0.5, tri * 3, 1.0,   1.0, tri * 2, 1.0,
        1.0, tri * 2, 1.0,   0.5, tri * 3, 1.0,   1.5, tri * 3, 1.0,
        1.0, tri * 2, 1.0,   1.5, tri * 3, 1.0,   2.0, tri * 2, 1.0,
        2.0, tri * 2, 1.0,   1.5, tri * 3, 1.0,   2.5, tri * 3, 1.0,
        2.0, tri * 2, 1.0,   2.5, tri * 3, 1.0,   3.0, tri * 2, 1.0,
        3.0, tri * 2, 1.0,   2.5, tri * 3, 1.0,   3.5, tri * 3, 1.0,
        3.0, tri * 2, 1.0,   3.5, tri * 3, 1.0,   4.0, tri * 2, 1.0,
        4.0, tri * 2, 1.0,   3.5, tri * 3, 1.0,   4.5, tri * 3, 1.0,
        4.0, tri * 2, 1.0,   4.5, tri * 3, 1.0,   5.0, tri * 2, 1.0,

        0.5, tri * 3, 1.0,   1.0, tri * 4, 1.0,   1.5, tri * 3, 1.0,
        1.5, tri * 3, 1.0,   1.0, tri * 4, 1.0,   2.0, tri * 4, 1.0,
        1.5, tri * 3, 1.0,   2.0, tri * 4, 1.0,   2.5, tri * 3, 1.0,
        2.5, tri * 3, 1.0,   2.0, tri * 4, 1.0,   3.0, tri * 4, 1.0,
        2.5, tri * 3, 1.0,   3.0, tri * 4, 1.0,   3.5, tri * 3, 1.0,
        3.5, tri * 3, 1.0,   3.0, tri * 4, 1.0,   4.0, tri * 4, 1.0,
        3.5, tri * 3, 1.0,   4.0, tri * 4, 1.0,   4.5, tri * 3, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 16; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4;
                const yOffset = y * 4 * tri + (x % 2) * 2 * tri;

                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createTriangle17TextureCoordinates() {
    const tri = Math.sqrt(3) / 2;

    const template = [
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,

        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.5, tri,   1.0, 0.0,   0.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
        0.0, 0.0,   0.5, tri,   1.0, 0.0,
        1.0, 0.0,   0.0, 0.0,   0.5, tri,
    ];

    return createTextureCoordinatesXY(template, 16, 32);
}

function createTriangle17Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
        24, 25, 26,   27, 28, 29,
        30, 31, 32,   33, 34, 35,
        36, 37, 38,   39, 40, 41,
        42, 43, 44,   45, 46, 47,
        48, 49, 50,   51, 52, 53,
        54, 55, 56,   57, 58, 59,
        60, 61, 62,   63, 64, 65,
        66, 67, 68,   69, 70, 71,
        72, 73, 74,   75, 76, 77,
        78, 79, 80,   81, 82, 83,
        84, 85, 86,   87, 88, 89,
        90, 91, 92,   93, 94, 95,
    ];

    return createIndicesXY(template, 16, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 1
//////////////////////////////////////////////////////////////////////////////////

function createSquare1Positions() {
    const template = [
        0.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 0.0, 1.0,
        1.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare1TextureCoordinates() {
    const template = [
        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 64, 64);
}

function createSquare1Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
    ];

    return createIndicesXY(template, 64, 64);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 2
//////////////////////////////////////////////////////////////////////////////////

function createSquare2Positions() {
    const template = [
        0.0, 1.0, 1.0,   0.0, 2.0, 1.0,   1.0, 1.0, 1.0,
        1.0, 1.0, 1.0,   0.0, 2.0, 1.0,   1.0, 2.0, 1.0,

        0.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 0.0, 1.0,
        1.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare2TextureCoordinates() {
    const template = [
        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 1.0,   1.0, 0.0,   0.0, 1.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 64, 32);
}

function createSquare2Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndicesXY(template, 64, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 3
//////////////////////////////////////////////////////////////////////////////////

function createSquare3Positions() {
    const template = [
        0.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 0.0, 1.0,
        1.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 1.0, 1.0,

        1.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 0.0, 1.0,
        2.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare3TextureCoordinates() {
    const template = [
        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 64);
}

function createSquare3Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndicesXY(template, 32, 64);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 4
//////////////////////////////////////////////////////////////////////////////////

function createSquare4Positions() {
    const template = [
        0.0, 1.0, 1.0,   0.0, 2.0, 1.0,   1.0, 1.0, 1.0,
        1.0, 1.0, 1.0,   0.0, 2.0, 1.0,   1.0, 2.0, 1.0,

        1.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 1.0, 1.0,
        2.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 2.0, 1.0,

        0.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 0.0, 1.0,
        1.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 1.0, 1.0,

        1.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 0.0, 1.0,
        2.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare4TextureCoordinates() {
    const template = [
        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        0.0, 1.0,   0.0, 0.0,   1.0, 1.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,

        1.0, 1.0,   1.0, 0.0,   0.0, 1.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createSquare4Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 5
//////////////////////////////////////////////////////////////////////////////////

function createSquare5Positions() {
    const template = [
        0.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 0.0, 1.0,
        1.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 1.0, 1.0,

        0.5, 1.0, 1.0,   0.5, 2.0, 1.0,   1.5, 1.0, 1.0,
        1.5, 1.0, 1.0,   0.5, 2.0, 1.0,   1.5, 2.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare5TextureCoordinates() {
    const template = [
        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 64, 32);
}

function createSquare5Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndicesXY(template, 64, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 6
//////////////////////////////////////////////////////////////////////////////////

function createSquare6Positions() {
    const template = [
        0.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 0.0, 1.0,
        1.0, 0.0, 1.0,   0.0, 1.0, 1.0,   1.0, 1.0, 1.0,

        0.5, 1.0, 1.0,   0.5, 2.0, 1.0,   1.5, 1.0, 1.0,
        1.5, 1.0, 1.0,   0.5, 2.0, 1.0,   1.5, 2.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare6TextureCoordinates() {
    const template = [
        1.0, 1.0,   1.0, 0.0,   0.0, 1.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 64, 32);
}

function createSquare6Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
    ];

    return createIndicesXY(template, 64, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 7
//////////////////////////////////////////////////////////////////////////////////

function createSquare7Positions() {
    const template = [
        0.0, 1.0, 1.0,   0.0, 2.0, 1.0,   1.0, 1.0, 1.0,
        1.0, 1.0, 1.0,   0.0, 2.0, 1.0,   1.0, 2.0, 1.0,

        1.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 1.0, 1.0,
        2.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 2.0, 1.0,

        0.5, 0.0, 1.0,   0.5, 1.0, 1.0,   1.5, 0.0, 1.0,
        1.5, 0.0, 1.0,   0.5, 1.0, 1.0,   1.5, 1.0, 1.0,

        1.5, 0.0, 1.0,   1.5, 1.0, 1.0,   2.5, 0.0, 1.0,
        2.5, 0.0, 1.0,   1.5, 1.0, 1.0,   2.5, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare7TextureCoordinates() {
    const template = [
        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createSquare7Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 8
//////////////////////////////////////////////////////////////////////////////////

function createSquare8Positions() {
    const template = [
        0.0, 1.0, 1.0,   0.0, 2.0, 1.0,   1.0, 1.0, 1.0,
        1.0, 1.0, 1.0,   0.0, 2.0, 1.0,   1.0, 2.0, 1.0,

        1.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 1.0, 1.0,
        2.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 2.0, 1.0,

        0.5, 0.0, 1.0,   0.5, 1.0, 1.0,   1.5, 0.0, 1.0,
        1.5, 0.0, 1.0,   0.5, 1.0, 1.0,   1.5, 1.0, 1.0,

        1.5, 0.0, 1.0,   1.5, 1.0, 1.0,   2.5, 0.0, 1.0,
        2.5, 0.0, 1.0,   1.5, 1.0, 1.0,   2.5, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare8TextureCoordinates() {
    const template = [
        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        0.0, 1.0,   0.0, 0.0,   1.0, 1.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,

        1.0, 1.0,   1.0, 0.0,   0.0, 1.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createSquare8Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 9
//////////////////////////////////////////////////////////////////////////////////

function createSquare9Positions() {
    const template = [
        0.0, 1.5, 1.0,   0.0, 2.5, 1.0,   1.0, 1.5, 1.0,
        1.0, 1.5, 1.0,   0.0, 2.5, 1.0,   1.0, 2.5, 1.0,

        1.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 1.0, 1.0,
        2.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 2.0, 1.0,

        0.0, 0.5, 1.0,   0.0, 1.5, 1.0,   1.0, 0.5, 1.0,
        1.0, 0.5, 1.0,   0.0, 1.5, 1.0,   1.0, 1.5, 1.0,

        1.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 0.0, 1.0,
        2.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare9TextureCoordinates() {
    const template = [
        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createSquare9Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 9
//////////////////////////////////////////////////////////////////////////////////

function createSquare10Positions() {
    const template = [
        0.0, 1.5, 1.0,   0.0, 2.5, 1.0,   1.0, 1.5, 1.0,
        1.0, 1.5, 1.0,   0.0, 2.5, 1.0,   1.0, 2.5, 1.0,

        1.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 1.0, 1.0,
        2.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 2.0, 1.0,

        0.0, 0.5, 1.0,   0.0, 1.5, 1.0,   1.0, 0.5, 1.0,
        1.0, 0.5, 1.0,   0.0, 1.5, 1.0,   1.0, 1.5, 1.0,

        1.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 0.0, 1.0,
        2.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare10TextureCoordinates() {
    const template = [
        0.0, 1.0,   0.0, 0.0,   1.0, 1.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        0.0, 1.0,   0.0, 0.0,   1.0, 1.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createSquare10Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 11
//////////////////////////////////////////////////////////////////////////////////

function createSquare11Positions() {
    const template = [
        0.0, 1.5, 1.0,   0.0, 2.5, 1.0,   1.0, 1.5, 1.0,
        1.0, 1.5, 1.0,   0.0, 2.5, 1.0,   1.0, 2.5, 1.0,

        1.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 1.0, 1.0,
        2.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 2.0, 1.0,

        0.0, 0.5, 1.0,   0.0, 1.5, 1.0,   1.0, 0.5, 1.0,
        1.0, 0.5, 1.0,   0.0, 1.5, 1.0,   1.0, 1.5, 1.0,

        1.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 0.0, 1.0,
        2.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare11TextureCoordinates() {
    const template = [
        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 1.0,   1.0, 0.0,   0.0, 1.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        1.0, 1.0,   1.0, 0.0,   0.0, 1.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createSquare11Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Square 12
//////////////////////////////////////////////////////////////////////////////////

function createSquare12Positions() {
    const template = [
        0.0, 1.5, 1.0,   0.0, 2.5, 1.0,   1.0, 1.5, 1.0,
        1.0, 1.5, 1.0,   0.0, 2.5, 1.0,   1.0, 2.5, 1.0,

        1.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 1.0, 1.0,
        2.0, 1.0, 1.0,   1.0, 2.0, 1.0,   2.0, 2.0, 1.0,

        0.0, 0.5, 1.0,   0.0, 1.5, 1.0,   1.0, 0.5, 1.0,
        1.0, 0.5, 1.0,   0.0, 1.5, 1.0,   1.0, 1.5, 1.0,

        1.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 0.0, 1.0,
        2.0, 0.0, 1.0,   1.0, 1.0, 1.0,   2.0, 1.0, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                positions.push(template[i + 0] + x * 2);
                positions.push(template[i + 1] + y * 2);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createSquare12TextureCoordinates() {
    const template = [
        0.0, 0.0,   0.0, 1.0,   1.0, 0.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   1.0, 1.0,   0.0, 0.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        0.0, 1.0,   0.0, 0.0,   1.0, 1.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,

        1.0, 1.0,   1.0, 0.0,   0.0, 1.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createSquare12Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 1
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses1Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3, h * 1, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 3, 1.0,
        h * 3, h * 1, 1.0,   h * 3, h * 3, 1.0,   h * 4, h * 2, 1.0,

        h * 2, h * 2, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 4, 1.0,
        h * 2, h * 2, 1.0,   h * 2, h * 4, 1.0,   h * 3, h * 3, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses1TextureCoordinates() {
    const template = [
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses1Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 2
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses2Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3, h * 1, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 3, 1.0,
        h * 3, h * 1, 1.0,   h * 3, h * 3, 1.0,   h * 4, h * 2, 1.0,

        h * 2, h * 2, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 4, 1.0,
        h * 2, h * 2, 1.0,   h * 2, h * 4, 1.0,   h * 3, h * 3, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses2TextureCoordinates() {
    const template = [
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses2Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 3
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses3Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3, h * 1, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 3, 1.0,
        h * 3, h * 1, 1.0,   h * 3, h * 3, 1.0,   h * 4, h * 2, 1.0,

        h * 2, h * 2, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 4, 1.0,
        h * 2, h * 2, 1.0,   h * 2, h * 4, 1.0,   h * 3, h * 3, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses3TextureCoordinates() {
    const template = [
        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses3Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 4
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses4Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3, h * 1, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 3, 1.0,
        h * 3, h * 1, 1.0,   h * 3, h * 3, 1.0,   h * 4, h * 2, 1.0,

        h * 2, h * 2, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 4, 1.0,
        h * 2, h * 2, 1.0,   h * 2, h * 4, 1.0,   h * 3, h * 3, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses4TextureCoordinates() {
    const template = [
        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses4Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 5
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses5Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,
        h * 4, h * 0, 1.0,   h * 3, h * 1, 1.0,   h * 4, h * 2, 1.0,
        h * 4, h * 0, 1.0,   h * 4, h * 2, 1.0,   h * 5, h * 1, 1.0,
        h * 6, h * 0, 1.0,   h * 5, h * 1, 1.0,   h * 6, h * 2, 1.0,
        h * 6, h * 0, 1.0,   h * 6, h * 2, 1.0,   h * 7, h * 1, 1.0,
        h * 8, h * 0, 1.0,   h * 7, h * 1, 1.0,   h * 8, h * 2, 1.0,
        h * 8, h * 0, 1.0,   h * 8, h * 2, 1.0,   h * 9, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,
        h * 3, h * 1, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 3, 1.0,
        h * 3, h * 1, 1.0,   h * 3, h * 3, 1.0,   h * 4, h * 2, 1.0,
        h * 5, h * 1, 1.0,   h * 4, h * 2, 1.0,   h * 5, h * 3, 1.0,
        h * 5, h * 1, 1.0,   h * 5, h * 3, 1.0,   h * 6, h * 2, 1.0,
        h * 7, h * 1, 1.0,   h * 6, h * 2, 1.0,   h * 7, h * 3, 1.0,
        h * 7, h * 1, 1.0,   h * 7, h * 3, 1.0,   h * 8, h * 2, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 8 * h;
                const yOffset = y * 2 * h;
                
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses5TextureCoordinates() {
    const template = [
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,
        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,
        1.0, 1.0,   0.0, 1.0,   0.0, 0.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,
        1.0, 1.0,   0.0, 1.0,   0.0, 0.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses5Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
        24, 25, 26,   27, 28, 29,
        30, 31, 32,   33, 34, 35,
        36, 37, 38,   39, 40, 41,
        42, 43, 44,   45, 46, 47,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 6
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses6Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3.5, h * 0.5, 1.0,   h * 2.5, h * 1.5, 1.0,   h * 3.5, h * 2.5, 1.0,
        h * 3.5, h * 0.5, 1.0,   h * 3.5, h * 2.5, 1.0,   h * 4.5, h * 1.5, 1.0,

        h * 2.5, h * 1.5, 1.0,   h * 1.5, h * 2.5, 1.0,   h * 2.5, h * 3.5, 1.0,
        h * 2.5, h * 1.5, 1.0,   h * 2.5, h * 3.5, 1.0,   h * 3.5, h * 2.5, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses6TextureCoordinates() {
    const template = [
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses6Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 7
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses7Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3.5, h * 0.5, 1.0,   h * 2.5, h * 1.5, 1.0,   h * 3.5, h * 2.5, 1.0,
        h * 3.5, h * 0.5, 1.0,   h * 3.5, h * 2.5, 1.0,   h * 4.5, h * 1.5, 1.0,

        h * 2.5, h * 1.5, 1.0,   h * 1.5, h * 2.5, 1.0,   h * 2.5, h * 3.5, 1.0,
        h * 2.5, h * 1.5, 1.0,   h * 2.5, h * 3.5, 1.0,   h * 3.5, h * 2.5, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses7TextureCoordinates() {
    const template = [
        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses7Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 8
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses8Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3.5, h * 0.5, 1.0,   h * 2.5, h * 1.5, 1.0,   h * 3.5, h * 2.5, 1.0,
        h * 3.5, h * 0.5, 1.0,   h * 3.5, h * 2.5, 1.0,   h * 4.5, h * 1.5, 1.0,

        h * 2.5, h * 1.5, 1.0,   h * 1.5, h * 2.5, 1.0,   h * 2.5, h * 3.5, 1.0,
        h * 2.5, h * 1.5, 1.0,   h * 2.5, h * 3.5, 1.0,   h * 3.5, h * 2.5, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses8TextureCoordinates() {
    const template = [
        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses8Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 9
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses9Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3.5, h * 0.5, 1.0,   h * 2.5, h * 1.5, 1.0,   h * 3.5, h * 2.5, 1.0,
        h * 3.5, h * 0.5, 1.0,   h * 3.5, h * 2.5, 1.0,   h * 4.5, h * 1.5, 1.0,

        h * 2.5, h * 1.5, 1.0,   h * 1.5, h * 2.5, 1.0,   h * 2.5, h * 3.5, 1.0,
        h * 2.5, h * 1.5, 1.0,   h * 2.5, h * 3.5, 1.0,   h * 3.5, h * 2.5, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses9TextureCoordinates() {
    const template = [
        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        1.0, 1.0,   0.0, 1.0,   0.0, 0.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,

        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses9Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}

//////////////////////////////////////////////////////////////////////////////////
//// Rhombuses 10
//////////////////////////////////////////////////////////////////////////////////

function createRhombuses10Positions() {
    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,

        h * 3, h * 1, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 3, 1.0,
        h * 3, h * 1, 1.0,   h * 3, h * 3, 1.0,   h * 4, h * 2, 1.0,

        h * 2, h * 2, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 4, 1.0,
        h * 2, h * 2, 1.0,   h * 2, h * 4, 1.0,   h * 3, h * 3, 1.0,
    ];

    let positions = [];

    for (let y = 0; y < 32; y++) {
        for (let x = 0; x < 32; x++) {
            for (let i = 0; i < template.length; i += 3) {
                const xOffset = x * 4 * h + (y % 2) * 2 * h;
                const yOffset = y * 2 * h;
                positions.push(template[i + 0] + xOffset);
                positions.push(template[i + 1] + yOffset);
                positions.push(template[i + 2]);
            }
        }
    }

    return positions;
}

function createRhombuses10TextureCoordinates() {
    const template = [
        0.0, 1.0,   1.0, 1.0,   1.0, 0.0,
        0.0, 1.0,   1.0, 0.0,   0.0, 0.0,

        1.0, 1.0,   0.0, 1.0,   0.0, 0.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,

        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,

        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
    ];

    return createTextureCoordinatesXY(template, 32, 32);
}

function createRhombuses10Indices() {
    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
    ];

    return createIndicesXY(template, 32, 32);
}