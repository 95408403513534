import { RenderType } from '../types';
import {
  BackwardPattern,
  BlockPattern,
  BoxPattern,
  BrickPattern,
  BumpsPattern,
  CurtainPattern,
  DiamondPattern,
  EaglePattern,
  EchoPattern,
  FollowPattern,
  ForwardPattern,
  GroupPattern,
  HexagonPattern,
  HexaPlusPattern,
  HivePattern,
  LeftPattern,
  LevelsPattern,
  MosaicPattern,
  OpenupPattern,
  OppositePattern,
  OverlapPattern,
  PineapplePattern,
  PlatePattern,
  PlayPattern,
  ReflectionPattern,
  RepeatPattern,
  RespondPattern,
  RoundPattern,
  RythmPattern,
  SlicesPattern,
  SnapPattern,
  SquarePattern,
  StairsPattern,
  StripesPattern,
  UnfoldPattern,
  UprightPattern,
  WallPattern,
  WavePattern,
  WindowPattern,
} from './PatternIcons';

export function getPatternIcon(renderType: RenderType, className: string | undefined = undefined) {
  switch (renderType)
  {
    case RenderType.Squares1: return { name: 'Window', icon: (<WindowPattern className={className}></WindowPattern>) };
    case RenderType.Squares2: return { name: 'Reflection', icon: (<ReflectionPattern className={className}></ReflectionPattern>) };
    case RenderType.Squares3: return { name: 'Curtain', icon: (<CurtainPattern className={className}></CurtainPattern>) };
    case RenderType.Squares4: return { name: 'Round', icon: (<RoundPattern className={className}></RoundPattern>) };
    case RenderType.Squares5: return { name: 'Brick', icon: (<BrickPattern className={className}></BrickPattern>) };
    case RenderType.Squares6: return { name: 'Wall', icon: (<WallPattern className={className}></WallPattern>) };
    case RenderType.Squares7: return { name: 'Repeat', icon: (<RepeatPattern className={className}></RepeatPattern>) };
    case RenderType.Squares8: return { name: 'Wave', icon: (<WavePattern className={className}></WavePattern>) };
    case RenderType.Squares9: return { name: 'Block', icon: (<BlockPattern className={className}></BlockPattern>) };
    case RenderType.Squares10: return { name: 'Opposite', icon: (<OppositePattern className={className}></OppositePattern>) };
    case RenderType.Squares11: return { name: 'Left', icon: (<LeftPattern className={className}></LeftPattern>) };
    case RenderType.Squares12: return { name: 'Respond', icon: (<RespondPattern className={className}></RespondPattern>) };

    case RenderType.Triangles1: return { name: 'Stripes', icon: (<StripesPattern className={className}></StripesPattern>) };
    case RenderType.Triangles2: return { name: 'Slices', icon: (<SlicesPattern className={className}></SlicesPattern>) };
    case RenderType.Triangles3: return { name: 'Rythm', icon: (<RythmPattern className={className}></RythmPattern>) };
    case RenderType.Triangles4: return { name: 'Hexagon', icon: (<HexagonPattern className={className}></HexagonPattern>) };
    case RenderType.Triangles5: return { name: 'Hexa+', icon: (<HexaPlusPattern className={className}></HexaPlusPattern>) };
    case RenderType.Triangles6: return { name: 'Backward', icon: (<BackwardPattern className={className}></BackwardPattern>) };
    case RenderType.Triangles7: return { name: 'Forward', icon: (<ForwardPattern className={className}></ForwardPattern>) };
    case RenderType.Triangles8: return { name: 'Play', icon: (<PlayPattern className={className}></PlayPattern>) };
    case RenderType.Triangles9: return { name: 'Eagle', icon: (<EaglePattern className={className}></EaglePattern>) };
    case RenderType.Triangles10: return { name: 'Echo', icon: (<EchoPattern className={className}></EchoPattern>) };
    case RenderType.Triangles11: return { name: 'Hive', icon: (<HivePattern className={className}></HivePattern>) };
    case RenderType.Triangles12: return { name: 'Plate', icon: (<PlatePattern className={className}></PlatePattern>) };
    case RenderType.Triangles13: return { name: 'Mosaic', icon: (<MosaicPattern className={className}></MosaicPattern>) };
    case RenderType.Triangles14: return { name: 'Overlap', icon: (<OverlapPattern className={className}></OverlapPattern>) };
    case RenderType.Triangles15: return { name: 'Bumps', icon: (<BumpsPattern className={className}></BumpsPattern>) };
    case RenderType.Triangles16: return { name: 'Unfold', icon: (<UnfoldPattern className={className}></UnfoldPattern>) };
    case RenderType.Triangles17: return { name: 'Openup', icon: (<OpenupPattern className={className}></OpenupPattern>) };

    case RenderType.Rhombuses1: return { name: 'Pineapple', icon: (<PineapplePattern className={className}></PineapplePattern>) };
    case RenderType.Rhombuses2: return { name: 'Diamond', icon: (<DiamondPattern className={className}></DiamondPattern>) };
    case RenderType.Rhombuses3: return { name: 'Levels', icon: (<LevelsPattern className={className}></LevelsPattern>) };
    case RenderType.Rhombuses4: return { name: 'Upright', icon: (<UprightPattern className={className}></UprightPattern>) };
    case RenderType.Rhombuses5: return { name: 'Box', icon: (<BoxPattern className={className}></BoxPattern>) };
    case RenderType.Rhombuses6: return { name: 'Stairs', icon: (<StairsPattern className={className}></StairsPattern>) };
    case RenderType.Rhombuses7: return { name: 'Snap', icon: (<SnapPattern className={className}></SnapPattern>) };
    case RenderType.Rhombuses8: return { name: 'Follow', icon: (<FollowPattern className={className}></FollowPattern>) };
    case RenderType.Rhombuses9: return { name: 'Group', icon: (<GroupPattern className={className}></GroupPattern>) };
    case RenderType.Rhombuses10: return { name: 'Square', icon: (<SquarePattern className={className}></SquarePattern>) };
  }
}