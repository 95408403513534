import classNames from 'classnames';
import { useRef, useState } from 'react';

import { useScrollToRenderType, useUpdatePatternFilter } from '../../hooks';
import { getPatternIcon } from '../../icons/getPatternIcon';
import {
    AdjustIcon,
    CropIcon,
    MinusIcon,
    PlusIcon,
    RepatternIcon,
    RhombusIcon,
    SquareIcon,
    TriangleIcon,
} from '../../icons/Icons';
import { allPatterns, PatternType, RenderType } from '../../types';
import { updatePatternFilter } from '../../utils/updatePatternFilter';
import classes from './CameraControlsMenu.module.scss';

export interface CameraControlsMenuProps {
    renderType: RenderType;
    onRenderTypeChanged: (renderType: RenderType) => void;
    tileCropSize: number;
    onTileCropSizeChanged: (size: number) => void;
    track: MediaStreamTrack | undefined;
}


let defaultExposureTime: number | undefined = undefined;

export default function CameraControlsMenu(props: CameraControlsMenuProps) {
    const [selectedMenu, setSelectedMenu] = useState<MenuSelection>('none');

    const [patternFilter, setPatternFilter] = useState<PatternType>('triangles');
    const patternContainer = useRef<HTMLDivElement>(null);

    useScrollToRenderType(props.renderType);
    useUpdatePatternFilter(patternFilter, setPatternFilter, patternContainer);    

    const capabilities = props.track?.getCapabilities() as any;
    const settings = props.track?.getSettings() as any;

    defaultExposureTime = defaultExposureTime ?? settings?.exposureTime;

    // console.log(capabilities);
    // console.log(settings);

    const [exposure, setExposure] = useState({ 
        editable: capabilities?.exposureMode !== undefined,
        min: capabilities?.exposureTime?.min as number ?? 0,
        max: capabilities?.exposureTime?.max as number ?? 0,
        step: capabilities?.exposureTime?.step as number ?? 0,
        value: settings?.exposureTime as number });
    
    const [brightness, setBrightness] = useState({ 
        editable: capabilities?.brightness !== undefined,
        min: capabilities?.brightness?.min as number ?? 0,
        max: capabilities?.brightness?.max as number ?? 0,
        step: capabilities?.brightness?.step as number ?? 0,
        value: settings?.brightness as number });

    if (!exposure.editable && capabilities?.exposureMode !== undefined) {
        setExposure({ ...exposure, editable: capabilities?.exposureMode !== undefined });
    }

    if (!brightness.editable && capabilities?.brightness !== undefined) {
        setBrightness({ ...brightness, editable: capabilities?.brightness !== undefined });
    }

    // console.log(exposure);
    // console.log(brightness);

    // if (exposure.value !== defaultExposureTime ) {
    //     props.track?.applyConstraints({ advanced: [ { exposureMode: 'manual' } as MediaTrackConstraintSet ]});
    //     props.track?.applyConstraints({ advanced: [ { exposureTime: exposure.value } as MediaTrackConstraintSet ]});
    // }
    // else {
    //     props.track?.applyConstraints({ advanced: [{ exposureMode: 'continuous' } as MediaTrackConstraintSet ]});
    // }
    
    // props.track?.applyConstraints({ advanced: [{ brightness: brightness.value } as MediaTrackConstraintSet ]});

    const onSelectedMenu = (menuSelection: MenuSelection) => {
        if (selectedMenu === menuSelection) {
            setSelectedMenu('none');
        }
        else {
            setSelectedMenu(menuSelection);
        }
    }

    return <div className={classNames(classes.menu, getClassNames(selectedMenu))}>
        <div className={classes.topMenu}>
            <div className={classNames(classes.item, selectedMenu === 'pattern' ? classes.selected : null)}
                onClick={() => onSelectedMenu('pattern')}>
                <RepatternIcon className={classes.icon}></RepatternIcon>
            </div>
            <div className={classNames(classes.item, selectedMenu === 'crop' ? classes.selected : null)}
                onClick={() => onSelectedMenu('crop')}>
                <CropIcon className={classes.icon}></CropIcon>
            </div>
            { exposure.editable && brightness.editable ?
                <div className={classNames(classes.item, selectedMenu === 'adjust' ? classes.selected : null)}
                    onClick={() => onSelectedMenu('adjust')}>
                    <AdjustIcon className={classes.icon}></AdjustIcon>
                </div>
                : null
            }
        </div>

        <div style={{ display: selectedMenu === 'pattern' ? 'block' : 'none '}}>
            <div className={classes.selectedMenuContainer}>
                <div className={classNames(classes.item, classes.left)}></div>
                <div className={classNames(classes.item, classes.middle)}>Select pattern</div>
                <div className={classNames(classes.item, classes.right)}></div>
            </div>

            <div className={classes.patternGroupsContainer}>
                <div onClick={() => updatePatternFilter('triangles', setPatternFilter)} className={classNames(classes.item, patternFilter === 'triangles' ? classes.selected : null)}>
                    <TriangleIcon className={classes.triangleIcon}></TriangleIcon>
                </div>
                <div onClick={() => updatePatternFilter('squares', setPatternFilter)} className={classNames(classes.item, patternFilter === 'squares' ? classes.selected : null)}>
                    <SquareIcon className={classes.itemIcon}></SquareIcon>
                </div>
                <div onClick={() => updatePatternFilter('rhombuses', setPatternFilter)} className={classNames(classes.item, patternFilter === 'rhombuses' ? classes.selected : null)}>
                    <RhombusIcon className={classes.triangleIcon}></RhombusIcon>
                </div>
            </div>

            <div className={classes.patternContainer} ref={patternContainer}>
                { allPatterns.map(x => (
                    <div onClick={() => props.onRenderTypeChanged(x)} className={classNames(classes.item, props.renderType === x ? classes.selected : null)} id={RenderType[x]} key={RenderType[x]}>
                        { getPatternIcon(x, classNames(classes.patternIcon, props.renderType === x ? classes.selected : null)).icon} 
                        <div className={classes.patternTitle}>{getPatternIcon(x).name}</div>
                    </div>
                ))}
            </div>

            <div className={classes.patternFooter}></div>
        </div>

        { selectedMenu === 'crop' ?
            <div>
                <div className={classes.selectedMenuContainer}>
                    <div className={classNames(classes.item, classes.left)}></div>
                    <div className={classNames(classes.item, classes.middle)}>Tile crop size</div>
                    <div className={classNames(classes.item, classes.right)}></div>
                </div>

                <div className={classes.tileCropSizeContainer}>
                    <div className={classes.item} onClick={() => { if (props.tileCropSize > 1) { props.onTileCropSizeChanged(props.tileCropSize - 1)} }}>
                        <div className={classes.correctionItem}>
                            <MinusIcon className={classes.icon}></MinusIcon>
                        </div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.title}>
                            {props.tileCropSize}X
                        </div>
                    </div>
                    <div className={classes.item} onClick={() => { if (props.tileCropSize < 6) { props.onTileCropSizeChanged(props.tileCropSize + 1)} }}>
                        <div className={classes.correctionItem}>
                            <PlusIcon className={classes.icon}></PlusIcon>
                        </div>
                    </div>
                </div>
            </div>
            : null
        }

        { selectedMenu === 'adjust' ?
            <div className={classes.adjustSettings}>
                <div className={classes.title}>Exposure</div>
                <div className={classes.sliderContainer}>
                    <input className={classes.slider} type='range' min={exposure.min} max={exposure.max} step={exposure.step} value={exposure.value} onChange={x => setExposure({...exposure, value: x.target.valueAsNumber })}></input>
                </div>

                <div className={classes.title}>Brightness</div>
                <div className={classes.sliderContainer}> 
                    <input className={classes.slider} type='range' min={brightness.min} max={brightness.max} step={brightness.step} value={brightness.value} onChange={x => setBrightness({...brightness, value: x.target.valueAsNumber })}></input>
                </div>
            </div>
            : null
        }
    </div>
}

type MenuSelection = 'none' | 'pattern' | 'crop' | 'adjust';

function getClassNames(selectedMenu: MenuSelection) {
    if (selectedMenu === 'none') {
        return classes.closedHeight;
    }
    else if (selectedMenu === 'pattern') {
        return classes.patternHeight;
    }
    else if (selectedMenu === 'crop') {
        return classes.tileCropSizeHeight;
    }
    else if (selectedMenu === 'adjust') {
        return classes.adjustHeight;
    }
    else {
        return classes.closedHeight;
    }
}


/*
aspectRatio
: 
{max: 2304, min: 0.0006510416666666666}
brightness
: 
{max: 255, min: 0, step: 1}
colorTemperature
: 
{max: 6500, min: 2000, step: 1}
contrast
: 
{max: 255, min: 0, step: 1}
deviceId
: 
"9ea3a0b83ed67f840d8af26d78d749ffbc15a53a5f4bd2cf63ec7bf01ba5b456"
exposureCompensation
: 
{max: 255, min: 0, step: 1}
exposureMode
: 
(2) ['continuous', 'manual']
exposureTime
: 
{max: 2500, min: 4.8828125, step: 4.8828125}
facingMode
: 
[]
focusDistance
: 
{max: 250, min: 0, step: 5}
focusMode
: 
(2) ['continuous', 'manual']
frameRate
: 
{max: 60, min: 0}
groupId
: 
"86ce8be0d3405d2f281c6f4c1f931938e8c01e00b149045bfe5e4982a246622f"
height
: 
{max: 1536, min: 1}
resizeMode
: 
(2) ['none', 'crop-and-scale']
saturation
: 
{max: 255, min: 0, step: 1}
sharpness
: 
{max: 255, min: 0, step: 1}
whiteBalanceMode
: 
(2) ['continuous', 'manual']
width
: 
{max: 2304, min: 1}
*/