import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import accessTokenService from './services/AccessTokenService';
import accountService from './services/AccountService';
import backendService from './services/BackendService';
import classes from './TopMenu.module.scss';

export const topMenuHeight = 100;

export default function TopMenu() {
  const navigate = useNavigate();
  const account = useObservableState(accountService.onAccount(), null);
  const [state, setState] = React.useState({isOpen: false});

  const menuItems = ['My patterns', 'Logout'];

  const onMenuItemSelected: (item: string) => void = item => {
    console.log(item);
    if (item === 'My patterns') {
      navigate('/gallery');
    }
    else if (item === 'Logout') {
      accessTokenService.resetAccessToken();
      navigate('/createaccount');
    }

    setState({ isOpen: false });
  }

  return <div className={classes.root}>
    <div className={classes.bar}>
      <div onClick={_ => navigate('/camera')} className={classes.left}>
        Pattern it!
      </div>
      <div onClick={_ => setState({ isOpen: !state.isOpen })} className={classes.right}>
        <svg width='50px' height='50px' id='Layer1' viewBox='0 0 32 32' stroke='#ffffff' fill='#ffffff' ><path d='M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z'/></svg>
      </div>
    </div>

    {state.isOpen
      ? <div className={classes.overlay}>
          <div className={`${classes.bar} ${classes.openBar}`}>
            <div onClick={_ => navigate('/camera')} className={classes.left}>
              Hello {account?.name.split(' ')[0]}
            </div>
            <div onClick={_ => setState({ isOpen: !state.isOpen })} className={classes.right}>
              <svg width='50px' height='50px' viewBox="0 0 100 100" stroke='#000000'><path strokeWidth='12' strokeLinecap='square' d='M 10,10 90,90 M 10,90 90,10'/></svg>
            </div>
          </div>

          <div className={classes.openMenu}>
            <div className={classes.openMenuLeft}>
              <img className={classes.profileImage} src={backendService.getGetProfileImageUrl()} alt='profile'></img>
            </div>
            <div className={classes.openMenuRight}>
              { menuItems.map(x => <div className={classes.openMenuRightItem} onClick={_ => onMenuItemSelected(x)} key={x}>{x}</div>) }
            </div>
          </div>
        </div>
      : null}
  </div>;
}


