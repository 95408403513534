import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import backendService from '../../services/BackendService';
import imagesService from '../../services/ImagesService';
import routerService from '../../services/RouterService';
import { FilterType } from '../../types';
import { useImageSearchParams } from '../../hooks/useImageSearchParams';
import { AdjustBottomMenu } from '../bottomMenu/AdjustBottomMenu';
import { LoadingOverload } from '../overlay/LoadingOverload';
import classes from './AdjustImagePage.module.scss';


export function AdjustImagePage() {
  const params = useImageSearchParams();
  const navigate = useNavigate();

  const image = imagesService.imageById(params.imageId);

  const [loading, setLoading] = useState(true);

  const [filterType, setFilterType] = useState(image?.filterType ?? FilterType.None);

  const [brightness, setBrightness] = useState(image?.brightness ?? 0);
  const debouncedBrightness = useDebounce<number>(brightness, 500);
  const [contrast, setContrast] = useState(image?.contrast ?? 0);
  const debouncedContrast = useDebounce<number>(contrast, 500);
  const [hue, setHue] = useState(image?.hue ?? 0);  
  const debouncedHue = useDebounce<number>(hue, 500);
  const [saturation, setSaturation] = useState(image?.saturation ?? 0);
  const debouncedSaturation = useDebounce<number>(saturation, 500);  

  useEffect(() =>{
    setLoading(true);
  }, [filterType, debouncedBrightness, debouncedContrast, debouncedHue, debouncedSaturation]);

  if (image === null) {
    return <div></div>;
  }

  var imageParameter = {
    imageId: params.imageId,
    renderType: params.renderType ?? image.renderType,
    filterType: filterType,
    brightness: debouncedBrightness, 
    contrast: debouncedContrast,
    hue: debouncedHue,
    saturation: debouncedSaturation
  }

  const onSave = () => {
    navigate(routerService.getFullscreenImageUrlWithImageParameters(imageParameter));
  };

  return <div className={classes.page}>
    <div>
      <img onLoad={() => setLoading(false)} className={classes.image} src={backendService.getGetImagePreviewUrl(imageParameter)} alt='pattern'></img>
      { loading ? <img className={classes.image} src={backendService.getGetImageUrl(params.imageId)} alt='pattern'></img> : null }
      { loading ? <LoadingOverload></LoadingOverload> : null}
    </div>

    <AdjustBottomMenu
      filterType={filterType}
      brightness={brightness}
      contrast={contrast}
      hue={hue}
      saturation={saturation}
      onBack={() => navigate(routerService.getFullscreenImageUrl(params, 2))}
      onSave={onSave}
      onFilterTypeChanged={setFilterType}
      onBrightnessChanged={setBrightness}
      onContrastChanged={setContrast}
      onHueChanged={setHue}
      onSaturationChanged={setSaturation}
      ></AdjustBottomMenu>
  </div>
}
