import {
  AddToCollectionIcon,
  AdjustIcon,
  BackIcon,
  DeleteIcon,
  MagicWandIcon,
  RepatternIcon,
  SaveIcon,
  ShareIcon,
} from '../../icons/Icons';
import classes from './BottomMenu.module.scss';

export interface ItemBottomMenuProps {
  menuItems: MenuItem[];
  
  onBack?: () => void | undefined;

  onSave?: () => void | undefined;
}

export type IconType = 'edit' | 'share' | 'add' | 'delete' | 'repattern' | 'adjust';

export interface MenuItem {
  title: string;

  icon: IconType;

  onClick: () => void;
}

export function ItemBottomMenu(props: ItemBottomMenuProps) {
  return <div className={`${classes.root} ${classes.normalMenu}`}>
    <div className={classes.titleContainer}>
      {props.onBack 
        ? <div className={`${classes.item} ${classes.left}`} onClick={props.onBack}><BackIcon className={classes.icon}></BackIcon></div>
        : <div className={`${classes.item} ${classes.left}`}></div>
      }

      <div className={`${classes.item} ${classes.middle}`}></div>

      {props.onSave
        ? <div className={`${classes.item} ${classes.right}`} onClick={props.onSave}><SaveIcon className={classes.icon}></SaveIcon></div>
        : <div className={`${classes.item} ${classes.right}`}></div>
      }
    </div>

    <div className={classes.menuItemContainer}>
      {props.menuItems.map(item => 
        <div className={classes.item} onClick={item.onClick} key={item.title}>
          {getIcon(item.icon)}
          <div>{item.title}</div>
        </div>)
      }
    </div>
  </div>
}

function getIcon(icon: IconType) {
  if (icon === 'add') {
    return <AddToCollectionIcon className={classes.icon}></AddToCollectionIcon>
  }
  else if (icon === 'delete') {
    return <DeleteIcon className={classes.icon}></DeleteIcon>
  }
  else if (icon === 'edit') {
    return <MagicWandIcon className={classes.icon}></MagicWandIcon>;
  }
  else if (icon === 'share') {
    return <ShareIcon className={classes.icon}></ShareIcon>
  }
  else if (icon === 'repattern') {
    return <RepatternIcon className={classes.icon}></RepatternIcon>
  }
  else if (icon === 'adjust') {
    return <AdjustIcon className={classes.icon}></AdjustIcon>
  }

  return null;
}