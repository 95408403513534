import imagesService from "../../services/ImagesService";
import { RenderType } from "../../types";

const maxFrameCount = 15 * 30;
const maxTimeBetweenFrames = 20;

let state: VideoState = { 
    enabled: false,
    startTime: 0,
    frameCount: 0,
    frames: []};

export function getVideoFrames() {
    return {
        count: state.frames.length,
        maxFrameCount: maxFrameCount,
        enabled: state.enabled,
    };
}

export function startVideoRecording(
    renderType: RenderType,
    tileContext: CanvasRenderingContext2D,
    thumbnailDataUrl: string) {

    if (state.enabled) return;
    
    console.log('Starting video recording');
    
    state.enabled = true;
    state.startTime = Date.now();
    state.frameCount = 0;
    state.frames = [];

    state = { enabled: true, startTime: Date.now(), frameCount: 0, frames: []}

    recorder(renderType, tileContext, thumbnailDataUrl);
}

export function stopVideoRecording() {
    console.log('Stopping video recording');
    state.enabled = false;
}

let lastVideoFrame = 0;

function recorder(
    renderType: RenderType,
    tileContext: CanvasRenderingContext2D,
    thumbnailDataUrl: string
) {
    if (!state.enabled) {
        const elapsedTime =  Date.now() - state.startTime;
        console.log(`Video recording complete with: ${state.frames.length} frames, ${state.frames.length * 1000 / elapsedTime}, (${elapsedTime})`);
        imagesService.createVideo(state.frames, renderType, thumbnailDataUrl);
        return;
    }

    let now = Date.now();
    let elapsed = now - lastVideoFrame;
    if (elapsed > 1000 / maxTimeBetweenFrames) {
        lastVideoFrame = now;
        state.frameCount++;

        (tileContext.canvas as HTMLCanvasElement).toBlob(blob => {
            state.frames.push(blob!);
        }); 
    
        if (state.frameCount >= maxFrameCount) {
            stopVideoRecording();
        }
    }

    requestAnimationFrame(() => recorder(renderType, tileContext, thumbnailDataUrl));
}

interface VideoState {
    enabled: boolean;
    startTime: number;
    frameCount: number;
    frames: Blob[];
}