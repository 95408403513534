import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin, { RenderProps } from 'react-facebook-login/dist/facebook-login-render-props';
import { useNavigate } from 'react-router-dom';

import AccessTokenService from '../../services/AccessTokenService';
import backendService from '../../services/BackendService';
import classes from './CreateAccount.module.scss';
import { EmailIcon, FacebookIcon, GoogleIcon, SignupLogoFullIcon, SignupLogoSingleIcon } from '../../icons/Icons';
import { LoadingOverload } from '../overlay/LoadingOverload';

export function CreateAccount() {
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: response => {
      console.log(`Google login response received`);
      console.log(response);
      console.log('Logging in with google');
      fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, { headers: new Headers({ Authorization: `Bearer ${response.access_token}`, Accept: 'application/json' })})
        .then(x => x.json())
        .then(x => {
          console.log(x);
          backendService
            .fetch(`/google-login?googleAccessToken=${response.access_token}&userId=${x.id}&name=${x.given_name} ${x.family_name}&profileImageUrl=${x.picture}`, { secure: false })
            .then(x =>x.text())
            .then(x => {
              console.log('Logged in');
              AccessTokenService.setAccessToken(x);
              navigate('/camera');
            })
            .catch(x => { 
              console.error(x);
              setIsLoggingIn(false);
            });
        })
        .catch(x => console.error(x));
    },
    onError: (error) => console.error('Login Failed:', error)
  });

  const responseFacebook = (response: ReactFacebookLoginInfo) => {
    console.log(`Facebook login response received`);
    console.log(response);
    if (response.accessToken) {
      console.log('Logging in with facebook');
      backendService
        .fetch(`/facebook-login?facebookAccessToken=${response.accessToken}&userId=${response.userID}&name=${response.name ?? ''}`, { secure: false })
        .then(x =>x.text())
        .then(x => {
          console.log('Logged in');
          AccessTokenService.setAccessToken(x);
          navigate('/camera');
        })
        .catch(x => { 
          console.error(x);
          setIsLoggingIn(false);
        });
    }
  }

  const onFacebookLoginClick = (renderProps: RenderProps) => {
    if (!isLoggingIn) {
      console.log('Requesting facebook login');
      setIsLoggingIn(true);
      renderProps.onClick(); 
    }
  }

  const onGoogleLoginClicked = (event: any) => {
    if (!isLoggingIn) {
      console.log('Requesting google login');
      setIsLoggingIn(true);
      googleLogin();
    }
  }

  return <div className={classes.root}>
    <SignupLogoSingleIcon className={classes.singleLogo}></SignupLogoSingleIcon>
    
    <div className={classes.title}>Log in with</div>

    <div className={classes.controlContainer}>      
      <FacebookLogin
        isDisabled={isLoggingIn}
        appId='612772626958730'
        autoLoad={false}
        fields='name,email'
        callback={responseFacebook}
        icon='fa-facebook'
        textButton='Facebook'
        disableMobileRedirect={true}
        render={renderProps => {
          return <button
            className={classes.button}
            onClick={_ => onFacebookLoginClick(renderProps)}>
              <FacebookIcon className={classes.icon}></FacebookIcon><div className={classes.text}>Facebook</div>
          </button>;
        }}
      />
    </div>

    <div className={classes.controlContainer}>
      <button className={classes.button} onClick={onGoogleLoginClicked}>
        <GoogleIcon className={classes.icon}></GoogleIcon><div className={classes.text}>Google</div>
      </button>
    </div>

    <div className={classes.controlContainer}>
      <button className={classes.button} onClick={() => navigate('/emaillogin')}>
        <EmailIcon className={classes.icon}></EmailIcon><div className={classes.text}>Email</div>
      </button>
    </div>

    <div className={classes.footer}>You don't have an account yet?</div>

    <div className={classes.signUp} onClick={() => navigate('/createemailaccount')}>Sign up!</div>

    <div className={classes.fullLogoContainer}>
      <SignupLogoFullIcon className={classes.fullLogo}></SignupLogoFullIcon>
    </div>

    { isLoggingIn ? <LoadingOverload></LoadingOverload> : null}
  </div>
}
