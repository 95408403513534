import image100 from './patternit_logo_anim_100.png';
import image101 from './patternit_logo_anim_101.png';
import image102 from './patternit_logo_anim_102.png';
import image103 from './patternit_logo_anim_103.png';
import image104 from './patternit_logo_anim_104.png';
import image105 from './patternit_logo_anim_105.png';
import image106 from './patternit_logo_anim_106.png';
import image107 from './patternit_logo_anim_107.png';
import image108 from './patternit_logo_anim_108.png';
import image109 from './patternit_logo_anim_109.png';
import image110 from './patternit_logo_anim_110.png';
import image111 from './patternit_logo_anim_111.png';
import image112 from './patternit_logo_anim_112.png';
import image113 from './patternit_logo_anim_113.png';
import image114 from './patternit_logo_anim_114.png';
import image115 from './patternit_logo_anim_115.png';
import image116 from './patternit_logo_anim_116.png';
import image117 from './patternit_logo_anim_117.png';
import image118 from './patternit_logo_anim_118.png';
import image119 from './patternit_logo_anim_119.png';
import image120 from './patternit_logo_anim_120.png';
import image121 from './patternit_logo_anim_121.png';
import image122 from './patternit_logo_anim_122.png';
import image123 from './patternit_logo_anim_123.png';
import image124 from './patternit_logo_anim_124.png';
import image125 from './patternit_logo_anim_125.png';
import image126 from './patternit_logo_anim_126.png';
import image127 from './patternit_logo_anim_127.png';
import image128 from './patternit_logo_anim_128.png';
import image129 from './patternit_logo_anim_129.png';
import image130 from './patternit_logo_anim_130.png';
import image131 from './patternit_logo_anim_131.png';
import image132 from './patternit_logo_anim_132.png';
import image133 from './patternit_logo_anim_133.png';
import image134 from './patternit_logo_anim_134.png';
import image135 from './patternit_logo_anim_135.png';
import image136 from './patternit_logo_anim_136.png';
import image137 from './patternit_logo_anim_137.png';
import image138 from './patternit_logo_anim_138.png';
import image139 from './patternit_logo_anim_139.png';
import image140 from './patternit_logo_anim_140.png';
import image141 from './patternit_logo_anim_141.png';
import image142 from './patternit_logo_anim_142.png';
import image143 from './patternit_logo_anim_143.png';
import image144 from './patternit_logo_anim_144.png';
import image145 from './patternit_logo_anim_145.png';
import image146 from './patternit_logo_anim_146.png';
import image147 from './patternit_logo_anim_147.png';
import image148 from './patternit_logo_anim_148.png';
import image149 from './patternit_logo_anim_149.png';
import image150 from './patternit_logo_anim_150.png';
import image151 from './patternit_logo_anim_151.png';
import image152 from './patternit_logo_anim_152.png';
import image153 from './patternit_logo_anim_153.png';
import image154 from './patternit_logo_anim_154.png';
import image155 from './patternit_logo_anim_155.png';
import image156 from './patternit_logo_anim_156.png';
import image157 from './patternit_logo_anim_157.png';
import image158 from './patternit_logo_anim_158.png';
import image159 from './patternit_logo_anim_159.png';
import image160 from './patternit_logo_anim_160.png';
import image161 from './patternit_logo_anim_161.png';
import image162 from './patternit_logo_anim_162.png';
import image163 from './patternit_logo_anim_163.png';
import image164 from './patternit_logo_anim_164.png';
import image165 from './patternit_logo_anim_165.png';
import image166 from './patternit_logo_anim_166.png';
import image167 from './patternit_logo_anim_167.png';
import image168 from './patternit_logo_anim_168.png';
import image169 from './patternit_logo_anim_169.png';
import image170 from './patternit_logo_anim_170.png';
import image171 from './patternit_logo_anim_171.png';
import image172 from './patternit_logo_anim_172.png';
import image173 from './patternit_logo_anim_173.png';
import image174 from './patternit_logo_anim_174.png';
import image175 from './patternit_logo_anim_175.png';
import image176 from './patternit_logo_anim_176.png';
import image177 from './patternit_logo_anim_177.png';
import image178 from './patternit_logo_anim_178.png';
import image179 from './patternit_logo_anim_179.png';
import image180 from './patternit_logo_anim_180.png';
import image181 from './patternit_logo_anim_181.png';
import image182 from './patternit_logo_anim_182.png';
import image183 from './patternit_logo_anim_183.png';
import image184 from './patternit_logo_anim_184.png';
import image185 from './patternit_logo_anim_185.png';
import image186 from './patternit_logo_anim_186.png';
import image187 from './patternit_logo_anim_187.png';
import image188 from './patternit_logo_anim_188.png';
import image189 from './patternit_logo_anim_189.png';
import image190 from './patternit_logo_anim_190.png';
import image191 from './patternit_logo_anim_191.png';
import image192 from './patternit_logo_anim_192.png';
import image193 from './patternit_logo_anim_193.png';
import image194 from './patternit_logo_anim_194.png';

export const patternLogo = [
  image100,
  image101,
  image102,
  image103,
  image104,
  image105,
  image106,
  image107,
  image108,
  image109,
  image110,
  image111,
  image112,
  image113,
  image114,
  image115,
  image116,
  image117,
  image118,
  image119,
  image120,
  image121,
  image122,
  image123,
  image124,
  image125,
  image126,
  image127,
  image128,
  image129,
  image130,
  image131,
  image132,
  image133,
  image134,
  image135,
  image136,
  image137,
  image138,
  image139,
  image140,
  image141,
  image142,
  image143,
  image144,
  image145,
  image146,
  image147,
  image148,
  image149,
  image150,
  image151,
  image152,
  image153,
  image154,
  image155,
  image156,
  image157,
  image158,
  image159,
  image160,
  image161,
  image162,
  image163,
  image164,
  image165,
  image166,
  image167,
  image168,
  image169,
  image170,
  image171,
  image172,
  image173,
  image174,
  image175,
  image176,
  image177,
  image178,
  image179,
  image180,
  image181,
  image182,
  image183,
  image184,
  image185,
  image186,
  image187,
  image188,
  image189,
  image190,
  image191,
  image192,
  image193,
  image194
];