import classes from './SplashPage.module.scss';
import Animation from '../../Animation';
import { logoImageElements } from '../../animations/logo';
import { useNavigate } from 'react-router-dom';

export function SplashPage() {
    const navigate = useNavigate();

    return <div className={classes.root}>
        <div className={classes.container}>
            <Animation className={classes.animation} images={logoImageElements} onEnd={() => navigate('/camera')}></Animation>
        </div>
    </div>;
}