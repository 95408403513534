import {
  BackIcon,
  SaveIcon,
} from '../../icons/Icons';
import classes from './BottomMenu.module.scss';

export interface SimpleBottomMenuProps {
  title: string;

  onBack?: () => void | undefined;

  onSave?: () => void | undefined;
}


export function SimpleBottomMenu(props: SimpleBottomMenuProps) {
  return <div className={`${classes.root} ${classes.smallMenu}`}>
    <div className={classes.titleContainer}>
      {props.onBack 
        ? <div className={`${classes.item} ${classes.left}`} onClick={props.onBack}><BackIcon className={classes.icon}></BackIcon></div>
        : <div className={`${classes.item} ${classes.left}`}></div>
      }

      <div className={`${classes.item} ${classes.middle}`}>{props.title}</div>

      {props.onSave
        ? <div className={`${classes.item} ${classes.right}`} onClick={props.onSave}><SaveIcon className={classes.icon}></SaveIcon></div>
        : <div className={`${classes.item} ${classes.right}`}></div>
      }
    </div>
  </div>
}
