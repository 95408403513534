import { useRef } from "react";
import { VideoRender } from "./VideoRender";
import { RenderType } from "../../types";


export function VideoRenderTestPage() {
    const tileCanvas = useRef<HTMLCanvasElement>(null);
    const video = useRef<HTMLVideoElement>(null);

    console.log('VideoRenderTestPage1');

    return <div style={{ width: '100%', height: '100%' }}>
        <VideoRender renderType={RenderType.Triangles5} tileCanvas={tileCanvas.current} video={video.current} ></VideoRender>

        <canvas
            id="tileCanvas"
            ref={tileCanvas}
            width={64}
            height={64}></canvas>

        <video
            autoPlay
            ref={video}
            style={{ display: 'none' }}/>
    </div>
}