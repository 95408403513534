import { useObservableState } from 'observable-hooks';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as TakeImagePressedSvg } from '../../icons/camera_button_state_pressed.svg';
import { HappyIcon, HourglassIcon, PlayIcon } from '../../icons/Icons';
import backendService from '../../services/BackendService';
import imagesService from '../../services/ImagesService';
import routerService from '../../services/RouterService';
import classes from './CameraControls.module.scss';
import { useLongPress } from 'use-long-press';
import { Image } from '../../types';
import { useImageUpdates } from '../../hooks/useImageUpdates';
import { useEffect, useState } from 'react';
import { getVideoFrames } from './videoRecorder';

interface CameraControlsProps {
  onTakePicture: () => void;
  onStartVideoRecording: () => void;
  onFinishVideoRecording: () => void;
  children: JSX.Element
}

export function CameraControls(props: CameraControlsProps) {
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [currentFrameCount, setCurrentFrameCount] = useState(0);
  
  const longPressBind = useLongPress(() => {
      if ('vibrate' in navigator) navigator.vibrate([100, 100, 100]);
      setIsRecording(true);
      props.onStartVideoRecording();
    },
    {
      onFinish: (event, meta) => {
        if ('vibrate' in navigator) navigator.vibrate(100);
        setIsRecording(false);
        props.onFinishVideoRecording();
      },
      onCancel: (event, meta) => {
        if (meta.reason === 'cancelled-by-release') {
          if ('vibrate' in navigator) navigator.vibrate(100);
          props.onTakePicture();
        }
      },
      filterEvents: (event) => true,
      threshold: 500,
      captureEvent: true,
      cancelOnMovement: false,
      cancelOutsideElement: true
    });

  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(() => {
        setCurrentFrameCount(getVideoFrames().count);
        if (!getVideoFrames().enabled) {
          setIsRecording(false);
        }
      }, 500);
      return () => clearInterval(interval);
    }
    else {
      return () => {};
    }
  }, [isRecording]);

  const images = useObservableState(imagesService.onImages(), []);
  const firstImages = images.slice(0, 20);

  useImageUpdates(images);

  const onImageClicked = (image: Image) => {
    if (!image.isRendering) {
      navigate(routerService.getFullscreenImageUrl({ imageId: image.imageId }, 1, true));
    }
  }

  const progress = getVideoFrames().count / getVideoFrames().maxFrameCount * 100;

  return <div className={classes.controls}>
    <div className={classes.topContainer}>
      <div className={classes.item}>
        {props.children}
      </div>
      <div {...longPressBind()} className={classes.item}>
        { !isRecording ? 
          <div className={classes.takeImageItem}>
            <TakeImagePressedSvg className={classes.takeImageItemPressed}></TakeImagePressedSvg>
          </div> : null}

        { isRecording ? 
          <div className={classes.videoRecodingProgress}>
            <svg width='192px' height='192px' xmlns="http://www.w3.org/2000/svg">
	            <circle className={classes.background} cx='96' cy='96' r='90' pathLength='100'></circle>
              <circle className={classes.progress} style={{ strokeDashoffset: 100 - progress}} cx='96' cy='96' r='90' pathLength='100'></circle>

              <circle className={classes.inner} cx='96' cy='96' r='20'></circle>
            </svg>
          </div> : null}
      </div>
      <div className={classes.item}>
        <div className={classes.cameraDirectionItem}>
          <HappyIcon className={classes.icon} onClick={() => window.location.reload()}></HappyIcon>
        </div>
      </div>
    </div>
    <div className={classes.previewContainer}>
      {firstImages.map((x, i) =>
        <div className={classes.item} key={`Pattern${i}`}>
          <img 
          className={classes.item}
          onClick={() => onImageClicked(x)}
          src={x.thumbnailDataUrl ? x.thumbnailDataUrl : backendService.getGetImageThumbnailUrl(x.imageId, x.renderType)} alt={`Pattern${i + 1}`}></img>
          {x.thumbnailDataUrl !== undefined || x.isRendering ? <HourglassIcon className={classes.itemIcon}></HourglassIcon> : null }
          {x.isVideo && !x.isRendering ? <PlayIcon className={classes.itemIcon}></PlayIcon> : null }
        </div>)}
    </div>
  </div>
}