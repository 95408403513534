import './fonts/Intro Regular Regular.otf';
import './index.modules.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { CameraPage, CreateAccount, GalleryPage } from './components';
import { AdjustImagePage } from './components/adjustImage/AdjustImagePage';
import { FullscreenImagePage } from './components/fullscreenImage/FullscreenImagePage';
import { RepatternImagePage } from './components/repatternImage/RepatternImagePage';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import { SplashPage } from './components/splash/SplashPage';
import { CreateEmailAccount } from './components/createAccount/CreateEmailAccount';
import { EmailLogin } from './components/createAccount/EmailLogin';
import { VideoRenderTestPage } from './components/videoRender/VideoRenderTestPage';
import { CollectionPage } from './components/collection/CollectionPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: '/camera',
        element: <CameraPage />
      },
      {
        path: '/splash',
        element: <SplashPage />
      },
      {
        path: '/createaccount',
        element: <CreateAccount />
      },
      {
        path: '/createemailaccount',
        element: <CreateEmailAccount />
      },
      {
        path: '/emaillogin',
        element: <EmailLogin />
      },
      {
        path: '/gallery',
        element: <GalleryPage />
      },
      {
        path: '/collection',
        element: <CollectionPage />
      },
      {
        path: '/fullscreen',
        element: <FullscreenImagePage />
      },
      {
        path: '/repattern',
        element: <RepatternImagePage />
      },
      {
        path: '/adjust',
        element: <AdjustImagePage />
      },
      {
        path: '/test',
        element: <VideoRenderTestPage />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GoogleOAuthProvider clientId="903777963079-ieoco942lauoi1n1pibjcsvt5tbko5iq.apps.googleusercontent.com">
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
