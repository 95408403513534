

export function createIndices(template: number[]): number[] {
    let indices = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i++) {
                indices.push(template[i] + x * template.length + y * template.length * 64);
            }
        }
    }

    return indices;
}

export function createIndicesXY(template: number[], countX: number, countY: number): number[] {
    let indices = [];

    for (let y = 0; y < countY; y++) {
        for (let x = 0; x < countX; x++) {
            for (let i = 0; i < template.length; i++) {
                indices.push(template[i] + x * template.length + y * template.length * countX);
            }
        }
    }

    return indices;
}

export function createTextureCoordinates(template: number[]): number[] {
    let textureCoordinates = [];

    for (let y = 0; y < 64; y++) {
        for (let x = 0; x < 64; x++) {
            for (let i = 0; i < template.length; i += 2) {
                textureCoordinates.push(template[i + 0]);
                textureCoordinates.push(template[i + 1]);
            }
        }
    }

    return textureCoordinates;
}

export function createTextureCoordinatesXY(template: number[], countX: number, countY: number): number[] {
    let textureCoordinates = [];

    for (let y = 0; y < countY; y++) {
        for (let x = 0; x < countX; x++) {
            for (let i = 0; i < template.length; i += 2) {
                textureCoordinates.push(template[i + 0]);
                textureCoordinates.push(template[i + 1]);
            }
        }
    }

    return textureCoordinates;
}