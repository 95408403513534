import { useEffect, useRef } from "react";
import { topMenuHeight } from "../../TopMenu";
import { Dimensions } from "../../renderings";
import { finalizeWebGl, initializeWebGl } from "./webGL";
import { useUserMedia } from "../../hooks/useUserMedia";
import { RenderType } from "../../types";

interface VideoRenderProps {
    renderType: RenderType;
    tileCanvas: HTMLCanvasElement | null;
    video: HTMLVideoElement | null;
}

let lastGlContext: WebGL2RenderingContext | null = null;

export function VideoRender(props: VideoRenderProps) {
    const { stream } = useUserMedia({ audio: false, video: { facingMode: 'environment' } });

    const previewDimensions: Dimensions = {
        width: window.innerWidth,
        height: window.innerHeight - topMenuHeight,
      };

    const previewCanvas = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        return () => {
            if (lastGlContext) {
                finalizeWebGl(lastGlContext);
            }
        };
    }, []);


    if (previewCanvas.current && props.tileCanvas && props.video) {
        props.video.setAttribute('autoplay', '');
        props.video.setAttribute('muted', '');
        props.video.setAttribute('playsinline', '');
        props.video.srcObject = stream;
        
        props.video.onloadedmetadata = () => {
            props.video!.play();
            const glContext = previewCanvas.current!.getContext('webgl2');
            const tileContext = props.tileCanvas!.getContext('2d');
            
            if (glContext !== null && tileContext !== null) {
                console.log('Initializing webgl');
                lastGlContext = glContext;
                initializeWebGl(glContext, props.renderType, tileContext, props.video!);
            }    
        }
    }

    return <div>
        <canvas 
            id="previewCanvas"
            ref={previewCanvas}
            width={previewDimensions.width}
            height={previewDimensions.height}></canvas>
    </div>
}