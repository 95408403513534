import classNames from 'classnames';
import { useRef, useState } from 'react';

import { useScrollToRenderType, useUpdatePatternFilter } from '../../hooks';
import { getPatternIcon } from '../../icons/getPatternIcon';
import { BackIcon, RhombusIcon, SaveIcon, SquareIcon, TriangleIcon } from '../../icons/Icons';
import { allPatterns, PatternType, RenderType } from '../../types';
import { updatePatternFilter } from '../../utils/updatePatternFilter';
import classes from './BottomMenu.module.scss';

export interface RepatternBottomMenuProps {
  renderType: RenderType,
  onBack: () => void;
  onSave: () => void;
  onRenderTypeChanged: (renderType: RenderType) => void;
}

export function RepatternBottomMenu(props: RepatternBottomMenuProps) {
  const [patternFilter, setPatternFilter] = useState<PatternType>('triangles');
  const patternContainer = useRef<HTMLDivElement>(null);

  useScrollToRenderType(props.renderType);
  useUpdatePatternFilter(patternFilter, setPatternFilter, patternContainer);

  return <div className={`${classes.root} ${classes.largeMenu}`}>
    <div className={classes.titleContainer}>
      <div className={classNames(classes.item, classes.left)} onClick={props.onBack}><BackIcon className={classes.icon}></BackIcon></div>
      <div className={classNames(classes.item, classes.middle)}>Select pattern</div>
      <div className={classNames(classes.item, classes.right)} onClick={props.onSave}><SaveIcon className={classes.icon}></SaveIcon></div>
    </div>

    <div className={classes.patternGroupsContainer}>
      <div onClick={() => updatePatternFilter('triangles', setPatternFilter)} className={classes.item}>
        <TriangleIcon className={classNames(classes.triangleIcon, patternFilter === 'triangles' ? classes.selected : null)}></TriangleIcon>
      </div>
      <div onClick={() => updatePatternFilter('squares', setPatternFilter)} className={classes.item}>
        <SquareIcon className={classNames(classes.itemIcon, patternFilter === 'squares' ? classes.selected : null)}></SquareIcon>
      </div>
      <div onClick={() => updatePatternFilter('rhombuses', setPatternFilter)} className={classes.item}>
        <RhombusIcon className={classNames(classes.triangleIcon, patternFilter === 'rhombuses' ? classes.selected : null)}></RhombusIcon>
      </div>
    </div>

    <div className={classes.patternContainer} ref={patternContainer}>
      { allPatterns.map(x => (
          <div onClick={() => props.onRenderTypeChanged(x)} className={classNames(classes.item, props.renderType === x ? classes.selected : null)} id={RenderType[x]} key={RenderType[x]}>
            { getPatternIcon(x, classNames(classes.patternIcon, props.renderType === x ? classes.selected : null)).icon} 
            <div className={classes.patternTitle}>{getPatternIcon(x).name}</div>
          </div>
      ))}
    </div>
  </div>
}
