import { FilterType } from '../types';
import { AdjustIcon, MagicWandIcon, SquareIcon } from './Icons';

export function getFilterIcon(filterType: FilterType, className: string) {
  switch (filterType)
  {
    case FilterType.None: return (<SquareIcon className={className}></SquareIcon>);
    case FilterType.Filter1: return (<MagicWandIcon className={className}></MagicWandIcon>);
    case FilterType.Filter2: return (<AdjustIcon className={className}></AdjustIcon>);
    case FilterType.Filter3: return (<MagicWandIcon className={className}></MagicWandIcon>);
    case FilterType.Filter4: return (<AdjustIcon className={className}></AdjustIcon>);
    case FilterType.Filter5: return (<MagicWandIcon className={className}></MagicWandIcon>);
    case FilterType.Filter6: return (<AdjustIcon className={className}></AdjustIcon>);
    case FilterType.Filter7: return (<MagicWandIcon className={className}></MagicWandIcon>);
  }
}