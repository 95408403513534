import { RefObject, useEffect } from 'react';

import { PatternType, RenderType, rhombusPatterns, squarePatterns, trianglePatterns } from '../types';

export function useUpdatePatternFilter(patternFilter: PatternType, setPatternFilter: (type: PatternType) => void, patternContainer: RefObject<HTMLDivElement>) {
  useEffect(() => {
    const container = patternContainer?.current;

    if (container) {
      const handleScroll = (event: Event) => {
        const containerRect = container.getBoundingClientRect();

        const getVisibleCount = (renderTypes: RenderType[]) => {
          return renderTypes
            .map(x => {
              const rect = document.querySelector(`#${RenderType[x]}`)!.getBoundingClientRect();
              return rect.x >= -rect.width && rect.x - rect.width <= containerRect.width ? 1 : 0 as number;
            })
            .reduce((p, c) => p + c);
        }

        const visibleTriangles = getVisibleCount(trianglePatterns);
        const visibleSquares = getVisibleCount(squarePatterns);
        const visibleRhombuses = getVisibleCount(rhombusPatterns);

        if (visibleTriangles > visibleSquares && patternFilter !== 'triangles') {
          setPatternFilter('triangles');
        }
        else if (visibleSquares > visibleTriangles && patternFilter !== 'squares') {
          setPatternFilter('squares');
        }
        else if (visibleSquares > visibleRhombuses && patternFilter !== 'squares') {
          setPatternFilter('squares');
        }
        else if (visibleRhombuses > visibleSquares && patternFilter !== 'rhombuses') {
          setPatternFilter('rhombuses');
        }
      };

      if (container) {
        container.addEventListener('scroll', handleScroll);
      }

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  });
}