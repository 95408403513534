import { useNavigate } from 'react-router-dom';
import { SignupLogoFullIcon, SignupLogoSingleIcon } from '../../icons/Icons';
import classes from './CreateAccount.module.scss';
import { LoadingOverload } from '../overlay/LoadingOverload';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import backendService from '../../services/BackendService';
import AccessTokenService from '../../services/AccessTokenService';


export function EmailLogin() {
    const navigate = useNavigate();
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const email = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);

    const onLogin = () => {
        setIsCreatingAccount(true);

        backendService
            .fetch(`/login?emailAddress=${email?.current?.value ?? ''}&password=${password?.current?.value ?? ''}`)
            .then(x => x.text())
            .then(x => {
                console.log('Logged in');
                AccessTokenService.setAccessToken(x);
                navigate('/camera');
            })
            .catch(x => { 
                setError('Invalid email or password');
                setIsCreatingAccount(false);
            });
    };

    return <div className={classes.root}>
        <SignupLogoSingleIcon className={classes.singleLogo}></SignupLogoSingleIcon>
    
        <div className={classes.title}>Log in</div>

        <div className={classes.controlContainer}>
            <input className={classNames(classes.input, classes.inputPadding)} type='email' placeholder='Email' ref={email}></input>
        </div>

        <div className={classes.controlContainer}>
            <input className={classNames(classes.input, classes.inputPadding)} type='password' placeholder='Password' ref={password}></input>
        </div>

        { error ? <div className={classes.error}>{error}</div> : null }

        <div className={classes.footerUnderlined}>Uhh, I totally forgot my password...</div>

        <div className={classes.controlContainer}>
            <button className={classes.inputButton} onClick={onLogin}>Let's start!</button>
        </div>

        <div className={classes.footer}>Do you want to log in another way?</div>

        <div className={classes.signUp} onClick={() => navigate('/createaccount')}>Back</div>

        <div className={classes.fullLogoContainer}>
            <SignupLogoFullIcon className={classes.fullLogo}></SignupLogoFullIcon>
        </div>

        { isCreatingAccount ? <LoadingOverload></LoadingOverload> : null}
    </div>;
}