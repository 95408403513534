import { mat4 } from "gl-matrix";
import { createGeometry } from "./geometry";
import { RenderType } from "../../types";

const globalState: GlobalState = {
    shaderProgram: null,
    programInfo: null,
    bufferInfo: null,
    texture: null
};

const debugMode = false;
const debugTexture = false;

function initPositionBuffer(gl: WebGL2RenderingContext) {
    const positionBuffer = gl.createBuffer();
    if (positionBuffer === null) throw new Error("Failed to create buffer");
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

    const h = Math.sqrt(2) / 2;

    const template = [
        h * 2, h * 0, 1.0,   h * 1, h * 1, 1.0,   h * 2, h * 2, 1.0,
        h * 2, h * 0, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 1, 1.0,
        h * 4, h * 0, 1.0,   h * 3, h * 1, 1.0,   h * 4, h * 2, 1.0,
        h * 4, h * 0, 1.0,   h * 4, h * 2, 1.0,   h * 5, h * 1, 1.0,
        h * 6, h * 0, 1.0,   h * 5, h * 1, 1.0,   h * 6, h * 2, 1.0,
        h * 6, h * 0, 1.0,   h * 6, h * 2, 1.0,   h * 7, h * 1, 1.0,
        h * 8, h * 0, 1.0,   h * 7, h * 1, 1.0,   h * 8, h * 2, 1.0,
        h * 8, h * 0, 1.0,   h * 8, h * 2, 1.0,   h * 9, h * 1, 1.0,

        h * 1, h * 1, 1.0,   h * 0, h * 2, 1.0,   h * 1, h * 3, 1.0,
        h * 1, h * 1, 1.0,   h * 1, h * 3, 1.0,   h * 2, h * 2, 1.0,
        h * 3, h * 1, 1.0,   h * 2, h * 2, 1.0,   h * 3, h * 3, 1.0,
        h * 3, h * 1, 1.0,   h * 3, h * 3, 1.0,   h * 4, h * 2, 1.0,
        h * 5, h * 1, 1.0,   h * 4, h * 2, 1.0,   h * 5, h * 3, 1.0,
        h * 5, h * 1, 1.0,   h * 5, h * 3, 1.0,   h * 6, h * 2, 1.0,
        h * 7, h * 1, 1.0,   h * 6, h * 2, 1.0,   h * 7, h * 3, 1.0,
        h * 7, h * 1, 1.0,   h * 7, h * 3, 1.0,   h * 8, h * 2, 1.0,
    ];

    

    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(template), gl.STATIC_DRAW);

    return positionBuffer;
}

function initTextureBuffer(gl: WebGL2RenderingContext) {
    const textureCoordBuffer = gl.createBuffer();
    if (textureCoordBuffer === null) throw new Error("Failed to create buffer");
    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);

    const template = [
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,
        1.0, 0.0,   0.0, 0.0,   0.0, 1.0,
        1.0, 0.0,   0.0, 1.0,   1.0, 1.0,

        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,
        0.0, 0.0,   1.0, 0.0,   1.0, 1.0,
        0.0, 0.0,   1.0, 1.0,   0.0, 1.0,
        1.0, 1.0,   0.0, 1.0,   0.0, 0.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,
        1.0, 1.0,   0.0, 1.0,   0.0, 0.0,
        1.0, 1.0,   0.0, 0.0,   1.0, 0.0,
    ];

    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(template), gl.STATIC_DRAW);

    return textureCoordBuffer;
}

function initIndexBuffer(gl: WebGL2RenderingContext) {
    const indexBuffer = gl.createBuffer();
    if (indexBuffer === null) throw new Error("Failed to create buffer");
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

    const template = [
        0, 1, 2,   3, 4, 5,
        6, 7, 8,   9, 10, 11,
        12, 13, 14,   15, 16, 17,
        18, 19, 20,   21, 22, 23,
        24, 25, 26,   27, 28, 29,
        30, 31, 32,   33, 34, 35,
        36, 37, 38,   39, 40, 41,
        42, 43, 44,   45, 46, 47,
    ];

    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(template), gl.STATIC_DRAW);

    return { indexBuffer, vertexCount: template.length };
}

//////////////////

export function initializeWebGl(
    gl: WebGL2RenderingContext,
    renderType: RenderType,
    tileContext: CanvasRenderingContext2D,
    video: HTMLVideoElement) {

    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

    if (globalState.shaderProgram === null) {
        globalState.shaderProgram = initializeShaderProgram(gl);

        globalState.programInfo = {
            program: globalState.shaderProgram,
            attribLocations: {
                vertexPosition: gl.getAttribLocation(globalState.shaderProgram, "aVertexPosition")!,
                textureCoord: gl.getAttribLocation(globalState.shaderProgram, "aTextureCoord")!,
            },
            uniformLocations: {
                projectionMatrix: gl.getUniformLocation(globalState.shaderProgram, "uProjectionMatrix")!,
                modelViewMatrix: gl.getUniformLocation(globalState.shaderProgram, "uModelViewMatrix")!,
                uSampler: gl.getUniformLocation(globalState.shaderProgram, "uSampler")!,
            },
        };
    }

    finalizeBuffers(gl, globalState.bufferInfo);
    globalState.bufferInfo = initializeBuffers(gl, renderType);

    globalState.texture = loadTexture(gl, 'Triangle.png', globalState.texture);
    // globalState.texture = loadTexture(gl, 'Square.png', globalState.texture);

    let loop = true;
    const render = () => {
        if (!loop) return;

        if (!debugMode && !debugTexture) {
            tileContext.drawImage(video, 300, 300, 64, 64, 0, 0, 64, 64);
            globalState.texture = initializeTexture(gl, tileContext.canvas, globalState.texture);
        }

        drawScene(gl, globalState.programInfo!, globalState.bufferInfo!, globalState.texture!);

        requestAnimationFrame(render);
    }

    requestAnimationFrame(render);

    return () => { loop = false; };
}

export function finalizeWebGl(gl: WebGL2RenderingContext) {
    if (globalState.shaderProgram) gl.deleteProgram(globalState.shaderProgram);
    globalState.shaderProgram = null;
    globalState.programInfo = null;
    
    finalizeBuffers(gl, globalState.bufferInfo);
    globalState.bufferInfo = null;

    if (globalState.texture) gl.deleteTexture(globalState.texture);
    globalState.texture = null;
}

function drawScene(gl: WebGL2RenderingContext, programInfo: ProgramInfo, buffers: BufferInfo, texture: WebGLTexture) {
    gl.clearColor(0.0, 0.0, 0.0, 1.0);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE);
    gl.enable(gl.BLEND);
    gl.disable(gl.DEPTH_TEST);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    const projectionMatrix = mat4.create();
    mat4.ortho(projectionMatrix, 0, gl.canvas.width / 64, 0, gl.canvas.height / 64, -1, 1);
    if (debugMode) mat4.ortho(projectionMatrix, 0, gl.canvas.width / 128, 0, gl.canvas.height / 128, -1, 1);

    const modelViewMatrix = mat4.create();
    mat4.translate(modelViewMatrix, modelViewMatrix, [-2.0, -2.0, 0.0]);
    if (debugMode) mat4.translate(modelViewMatrix, modelViewMatrix, [2.0, 6.0, 0.0]);

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
    gl.vertexAttribPointer(programInfo.attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
    
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
    gl.vertexAttribPointer(programInfo.attribLocations.textureCoord, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(programInfo.attribLocations.textureCoord);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);

    gl.useProgram(programInfo.program);

    gl.uniformMatrix4fv(programInfo.uniformLocations.projectionMatrix, false, projectionMatrix);
    gl.uniformMatrix4fv(programInfo.uniformLocations.modelViewMatrix, false, modelViewMatrix);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.uniform1i(programInfo.uniformLocations.uSampler, 0);

    gl.drawElements(gl.TRIANGLES, buffers.vertexCount, gl.UNSIGNED_SHORT, 0);
}

function initializeTexture(gl: WebGL2RenderingContext, tileCanvas: HTMLCanvasElement, texture: WebGLTexture | null) {
    if (texture === null) {
        texture = gl.createTexture();
        if (texture === null) throw new Error("Failed to create texture");
    }

    gl.bindTexture(gl.TEXTURE_2D, texture);
    
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, tileCanvas); // This is the important line!

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_NEAREST);
    gl.generateMipmap(gl.TEXTURE_2D);

    gl.bindTexture(gl.TEXTURE_2D, null);

    return texture;
}

function initializeBuffers(gl: WebGL2RenderingContext, renderType: RenderType): BufferInfo {
    if (!debugMode) {
        const geometry = createGeometry(renderType);

        const positionBuffer = gl.createBuffer();
        if (positionBuffer === null) throw new Error("Failed to create buffer");
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(geometry.positions), gl.STATIC_DRAW);

        const textureCoordBuffer = gl.createBuffer();
        if (textureCoordBuffer === null) throw new Error("Failed to create buffer");
        gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(geometry.textureCoordinates), gl.STATIC_DRAW);

        const indexBuffer = gl.createBuffer();
        if (indexBuffer === null) throw new Error("Failed to create buffer");
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(geometry.indices), gl.STATIC_DRAW);

        if (geometry.indices.length > 65536) {
            console.error(`Indices count: ${geometry.indices.length}`);
        }

        return {
            position: positionBuffer,
            textureCoord: textureCoordBuffer,
            indices: indexBuffer,
            vertexCount: geometry.indices.length,
        };
    }

    const positionBuffer = initPositionBuffer(gl);
    const textureCoordBuffer = initTextureBuffer(gl);
    const { indexBuffer, vertexCount } = initIndexBuffer(gl);

    return {
        position: positionBuffer,
        textureCoord: textureCoordBuffer,
        indices: indexBuffer,
        vertexCount: vertexCount,
    };
}

function finalizeBuffers(gl: WebGL2RenderingContext, buffers: BufferInfo | null) {
    if (buffers === null) return;
    gl.deleteBuffer(buffers.position);
    gl.deleteBuffer(buffers.textureCoord);
    gl.deleteBuffer(buffers.indices);
}

function initializeShaderProgram(gl : WebGL2RenderingContext) {
    const vertexShader = loadShaderProgram(gl, gl.VERTEX_SHADER, vertexShaderSource);
    const fragmentShader = loadShaderProgram(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);

    const shaderProgram = gl.createProgram();
    if (shaderProgram === null) throw new Error("Failed to create shader program");
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) throw new Error("Failed to create shader program");

    return shaderProgram;
}

function loadShaderProgram(gl: WebGL2RenderingContext, type: number, source: string) {
    const shader = gl.createShader(type);
    if (shader === null) throw new Error("Failed to create shader");

    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) throw new Error("Failed to create shader");

    return shader;
}

const vertexShaderSource = `
  attribute vec4 aVertexPosition;
  attribute vec2 aTextureCoord;

  uniform mat4 uModelViewMatrix;
  uniform mat4 uProjectionMatrix;

  varying highp vec2 vTextureCoord;

  void main(void) {
    gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
    vTextureCoord = aTextureCoord;
  }
`;

const fragmentShaderSource = `
  varying highp vec2 vTextureCoord;

  uniform sampler2D uSampler;

  void main(void) {
    gl_FragColor = texture2D(uSampler, vTextureCoord);
  }
`;

interface ProgramInfo {
    program: WebGLProgram;
    attribLocations: {
        vertexPosition: number;
        textureCoord: number;
    };
    uniformLocations: {
        projectionMatrix: WebGLUniformLocation;
        modelViewMatrix: WebGLUniformLocation;
        uSampler: WebGLUniformLocation;
    };
};

interface BufferInfo {
    position: WebGLBuffer;
    textureCoord: WebGLBuffer;
    indices: WebGLBuffer;
    vertexCount: number;
};

function loadTexture(gl: WebGL2RenderingContext, url: string, texture: WebGLTexture | null) {
    if (texture === null) {
        texture = gl.createTexture();
        if (texture === null) throw new Error("Failed to create texture");
    }

    gl.bindTexture(gl.TEXTURE_2D, texture);
    
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, new Uint8Array([0, 0, 0, 0]));
    const image = new Image();
    image.onload = () => {
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    };
    image.src = url;

    return texture;
}

interface GlobalState {
    shaderProgram: WebGLProgram | null;
    programInfo: ProgramInfo | null
    bufferInfo: BufferInfo | null;
    texture: WebGLTexture | null;
}