import { useNavigate } from 'react-router-dom';
import { SignupLogoFullIcon, SignupLogoSingleIcon } from '../../icons/Icons';
import classes from './CreateAccount.module.scss';
import { LoadingOverload } from '../overlay/LoadingOverload';
import { useRef, useState } from 'react';
import backendService from '../../services/BackendService';
import AccessTokenService from '../../services/AccessTokenService';
import classNames from 'classnames';


export function CreateEmailAccount() {
    const navigate = useNavigate();
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const email = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);

    const onSignUp = () => {
        const emailRegex = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$');
        
        const emailValid = emailRegex.test(email?.current?.value ?? '');
        const passwordValid = (password?.current?.value ?? '').length >= 5;
        
        if (emailValid && passwordValid) {
            setIsCreatingAccount(true);

            backendService
                .fetch(`/create-login?emailAddress=${email?.current?.value ?? ''}&password=${password?.current?.value ?? ''}`)
                .then(x => x.text())
                .then(x => {
                    console.log('Logged in');
                    AccessTokenService.setAccessToken(x);
                    navigate('/camera');
                })
                .catch(x => { 
                    setError('Email already registered');
                    setIsCreatingAccount(false);
                });
        }
        else if (!emailValid) {
            setError('Please enter a valid email address');
        }
        else if (!passwordValid) {
            setError('Please enter a valid password with 5 characters or more');
        }
    };

    return <div className={classes.root}>
        <SignupLogoSingleIcon className={classes.singleLogo}></SignupLogoSingleIcon>
    
        <div className={classes.title}>Sign up</div>

        <div className={classes.controlContainer}>
            <input className={classNames(classes.input, classes.inputPadding)} type='email' placeholder='Email' ref={email}></input>
        </div>

        <div className={classes.controlContainer}>
            <input className={classNames(classes.input, classes.inputPadding)} type='password' placeholder='Password' ref={password}></input>
        </div>

        { error ? <div className={classes.error}>{error}</div> : null }

        <div className={classes.controlContainer}>
            <button className={classes.inputButton} onClick={onSignUp}>Let's start!</button>
        </div>

        <div className={classes.footer}>Do you already have an account?</div>

        <div className={classes.signUp} onClick={() => navigate('/emaillogin')}>Log in</div>

        <div className={classes.fullLogoContainer}>
            <SignupLogoFullIcon className={classes.fullLogo}></SignupLogoFullIcon>
        </div>

        { isCreatingAccount ? <LoadingOverload></LoadingOverload> : null}
    </div>;
}