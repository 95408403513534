import { useState, useEffect } from "react";

export interface IConstraints {
  audio?: boolean;
  video?: {
    facingMode: "user" | "environment";
  };
}

export const useUserMedia = (constraints: IConstraints) => {
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    if (stream) return;
    let didCancel = false;

    const getUserMedia = async () => {
      if (!didCancel) {
        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
          setStream(mediaStream);
        } catch (e) {
          setError(e);
        }
      }
    };

    const cancel = () => {
      didCancel = true;

      // stream?.stop();
      // if (!stream) return;

      // if (stream?.getVideoTracks) {
      //   stream.getVideoTracks().map((track) => track.stop());
      // }
      // if (stream?.getAudioTracks) {
      //   stream.getAudioTracks().map((track) => track.stop());
      // }

      // stream.stop();
      // if (stream?.stop) {

      // }
    };

    getUserMedia();

    return cancel;
  }, [constraints, stream, error]);

  return { stream, error };
};
