import { ImageParameter } from "../types";

class RouterService {
  public getFullscreenImageUrl(image: { imageId: string }, menuLevel: number = 1, fromCamera: boolean = false): string {
    return `/fullscreen?imageId=${image.imageId}&menuLevel=${menuLevel}&fromCamera=${fromCamera}`;
  }

  public getFullscreenImageUrlWithImageParameters(image: ImageParameter, menuLevel: number = 2): string {
    return `/fullscreen?imageId=${image.imageId}${getImageParameters(image)}&menuLevel=${menuLevel}`;
  }

  public getRepatternImageUrl(image: ImageParameter): string {
    return `/repattern?imageId=${image.imageId}${getImageParameters(image)}`;
  }

  public getAdjustImageUrl(image: ImageParameter): string {
    return `/adjust?imageId=${image.imageId}${getImageParameters(image)}`;
  }

  public getCollectionUrl(collectionId: string): string {
    return `/collection?collectionId=${collectionId}`;
  }
}

const routerService = new RouterService();

export default routerService;

function getImageParameters(image: ImageParameter) {
  return `${getParameter('renderType', image.renderType)}${getParameter('filterType', image.filterType)}${getParameter('brightness', image.brightness)}${getParameter('contrast', image.contrast)}${getParameter('hue', image.hue)}${getParameter('saturation', image.saturation)}`;
}

function getParameter(name: string, value: number | string | undefined) {
  if (value !== undefined) {
    return `&${name}=${value}`;
  }
  else {
    return '';
  }
}