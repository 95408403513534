import { BehaviorSubject, Observable } from "rxjs";
import accessTokenService from "./AccessTokenService";
import { ImageParameter, RenderType } from "../types";
import { Guid } from "js-guid";
import { FilterType } from "../types";


export interface FetchOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  
  secure?: boolean;

  jsonBody?: string;

  formBody?: FormData;
}

export type BackendState = 'Ok' | 'AccessDenied';

class BackendService {
  private backendStateSubject = new BehaviorSubject<BackendState>('Ok');

  public onState(): Observable<BackendState> {
    return this.backendStateSubject.asObservable();
  }

  public fetch(resource: string, options?: FetchOptions): Promise<Response> {
    const headers = new Headers();
  
    const accessToken = accessTokenService.getAccessToken()

    if ((options?.secure === true || options?.secure === undefined) && accessToken !== null) {
      headers.append('Authorization', `Bearer ${accessToken}`);
    }
  
    if (options?.jsonBody && options?.method === 'POST') {
      headers.append('Content-Type', 'application/json');
    }
  
    const fetchOptions = {
      method: options?.method ?? 'GET',
      headers: headers,
      body: options?.jsonBody ?? options?.formBody,
    };
  
    return fetch(this.getBackendUrl(resource), fetchOptions)
      .then(x => {
        if (x.ok) {
          this.backendStateSubject.next('Ok');
          return x;
        }
        else if (x.status === 401 || x.status === 403) {
          this.backendStateSubject.next('AccessDenied');
        }
        
        console.error(x);
        throw new Error(x.statusText);
      });
  }

  public getBackendUrl(resource: string) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return `http://localhost:5215${resource}`;
    } else {
      return `${window.location.origin}${resource}`;
    }
  }

  public getGetImageUrl(imageId: string, withCacheBuster: boolean = false): string {
    return this.getBackendUrl(`/image?imageId=${imageId}&accessToken=${accessTokenService.getAccessToken()}${withCacheBuster ? `&guid=${Guid.newGuid()}` : ''}`);
  }

  public getGetImageThumbnailUrl(imageId: string, renderType: RenderType): string {
    return this.getBackendUrl(`/image?imageId=${imageId}&renderType=${RenderType[renderType]}&thumbnail=true&accessToken=${accessTokenService.getAccessToken()}`);
  }

  public getGetImageRepatternUrl(image: ImageParameter): string {
    return this.getBackendUrl(`/imagePreview?imageId=${image.imageId}&renderType=${RenderType[image.renderType]}&filterType=${FilterType[image.filterType]}&brightness=${image.brightness}&contrast=${image.contrast}&hue=${image.hue}&saturation=${image.saturation}&accessToken=${accessTokenService.getAccessToken()}`);
  }

  public getGetImagePreviewUrl(image: ImageParameter): string {
    return this.getBackendUrl(`/imagePreview?imageId=${image.imageId}&renderType=${RenderType[image.renderType]}&filterType=${FilterType[image.filterType]}&brightness=${image.brightness}&contrast=${image.contrast}&hue=${image.hue}&saturation=${image.saturation}&accessToken=${accessTokenService.getAccessToken()}`);
  }

  public getVideoUrl(image: ImageParameter): string {
    return this.getBackendUrl(`/videos?imageId=${image.imageId}&accessToken=${accessTokenService.getAccessToken()}`);
  }

  public getGetProfileImageUrl(): string {
    return this.getBackendUrl(`/profileImage?accessToken=${accessTokenService.getAccessToken()}`);
  }
}

const backendService = new BackendService();

export default backendService;