import { useSearchParams } from "react-router-dom";
import { FilterType, ImageParameterOptionals, RenderType } from "../types";

export function useImageSearchParams(): ImageParameterOptionals {
  const [params] = useSearchParams();

  return {
    imageId: params.get('imageId') ?? '',
    renderType: params.get('renderType') !== null ? parseInt(params.get('renderType')!, 10) as RenderType : undefined,
    filterType: params.get('filterType') !== null ? parseInt(params.get('filterType')!, 10) as FilterType : undefined,
    brightness: params.get('brightness') !== null ? parseInt(params.get('brightness')!, 10) : undefined,
    contrast: params.get('contrast') !== null ? parseInt(params.get('contrast')!, 10) : undefined,
    hue: params.get('hue') !== null ? parseInt(params.get('hue')!, 10) : undefined,
    saturation: params.get('saturation') !== null ? parseInt(params.get('saturation')!, 10) : undefined,
  };
}