import image000 from './patternit_progress_anim_boomerang000.png';
import image001 from './patternit_progress_anim_boomerang001.png';
import image002 from './patternit_progress_anim_boomerang002.png';
import image003 from './patternit_progress_anim_boomerang003.png';
import image004 from './patternit_progress_anim_boomerang004.png';
import image005 from './patternit_progress_anim_boomerang005.png';
import image006 from './patternit_progress_anim_boomerang006.png';
import image007 from './patternit_progress_anim_boomerang007.png';
import image008 from './patternit_progress_anim_boomerang008.png';
import image009 from './patternit_progress_anim_boomerang009.png';
import image010 from './patternit_progress_anim_boomerang010.png';
import image011 from './patternit_progress_anim_boomerang011.png';
import image012 from './patternit_progress_anim_boomerang012.png';
import image013 from './patternit_progress_anim_boomerang013.png';
import image014 from './patternit_progress_anim_boomerang014.png';
import image015 from './patternit_progress_anim_boomerang015.png';
import image016 from './patternit_progress_anim_boomerang016.png';
import image017 from './patternit_progress_anim_boomerang017.png';
import image018 from './patternit_progress_anim_boomerang018.png';
import image019 from './patternit_progress_anim_boomerang019.png';
import image020 from './patternit_progress_anim_boomerang020.png';
import image021 from './patternit_progress_anim_boomerang021.png';
import image022 from './patternit_progress_anim_boomerang022.png';
import image023 from './patternit_progress_anim_boomerang023.png';
import image024 from './patternit_progress_anim_boomerang024.png';
import image025 from './patternit_progress_anim_boomerang025.png';
import image026 from './patternit_progress_anim_boomerang026.png';
import image027 from './patternit_progress_anim_boomerang027.png';
import image028 from './patternit_progress_anim_boomerang028.png';
import image029 from './patternit_progress_anim_boomerang029.png';
import image030 from './patternit_progress_anim_boomerang030.png';
import image031 from './patternit_progress_anim_boomerang031.png';
import image032 from './patternit_progress_anim_boomerang032.png';
import image033 from './patternit_progress_anim_boomerang033.png';
import image034 from './patternit_progress_anim_boomerang034.png';
import image035 from './patternit_progress_anim_boomerang035.png';
import image036 from './patternit_progress_anim_boomerang036.png';
import image037 from './patternit_progress_anim_boomerang037.png';
import image038 from './patternit_progress_anim_boomerang038.png';
import image039 from './patternit_progress_anim_boomerang039.png';
import image040 from './patternit_progress_anim_boomerang040.png';
import image041 from './patternit_progress_anim_boomerang041.png';
import image042 from './patternit_progress_anim_boomerang042.png';
import image043 from './patternit_progress_anim_boomerang043.png';
import image044 from './patternit_progress_anim_boomerang044.png';
import image045 from './patternit_progress_anim_boomerang045.png';
import image046 from './patternit_progress_anim_boomerang046.png';
import image047 from './patternit_progress_anim_boomerang047.png';
import image048 from './patternit_progress_anim_boomerang048.png';
import image049 from './patternit_progress_anim_boomerang049.png';
import image050 from './patternit_progress_anim_boomerang050.png';
import image051 from './patternit_progress_anim_boomerang051.png';
import image052 from './patternit_progress_anim_boomerang052.png';
import image053 from './patternit_progress_anim_boomerang053.png';
import image054 from './patternit_progress_anim_boomerang054.png';
import image055 from './patternit_progress_anim_boomerang055.png';
import image056 from './patternit_progress_anim_boomerang056.png';
import image057 from './patternit_progress_anim_boomerang057.png';
import image058 from './patternit_progress_anim_boomerang058.png';
import image059 from './patternit_progress_anim_boomerang059.png';
import image060 from './patternit_progress_anim_boomerang060.png';
import image061 from './patternit_progress_anim_boomerang061.png';
import image062 from './patternit_progress_anim_boomerang062.png';
import image063 from './patternit_progress_anim_boomerang063.png';
import image064 from './patternit_progress_anim_boomerang064.png';
import image065 from './patternit_progress_anim_boomerang065.png';
import image066 from './patternit_progress_anim_boomerang066.png';
import image067 from './patternit_progress_anim_boomerang067.png';
import image068 from './patternit_progress_anim_boomerang068.png';
import image069 from './patternit_progress_anim_boomerang069.png';
import image070 from './patternit_progress_anim_boomerang070.png';
import image071 from './patternit_progress_anim_boomerang071.png';
import image072 from './patternit_progress_anim_boomerang072.png';
import image073 from './patternit_progress_anim_boomerang073.png';
import image074 from './patternit_progress_anim_boomerang074.png';
import image075 from './patternit_progress_anim_boomerang075.png';
import image076 from './patternit_progress_anim_boomerang076.png';
import image077 from './patternit_progress_anim_boomerang077.png';
import image078 from './patternit_progress_anim_boomerang078.png';
import image079 from './patternit_progress_anim_boomerang079.png';
import image080 from './patternit_progress_anim_boomerang080.png';
import image081 from './patternit_progress_anim_boomerang081.png';
import image082 from './patternit_progress_anim_boomerang082.png';
import image083 from './patternit_progress_anim_boomerang083.png';
import image084 from './patternit_progress_anim_boomerang084.png';
import image085 from './patternit_progress_anim_boomerang085.png';
import image086 from './patternit_progress_anim_boomerang086.png';
import image087 from './patternit_progress_anim_boomerang087.png';
import image088 from './patternit_progress_anim_boomerang088.png';
import image089 from './patternit_progress_anim_boomerang089.png';
import image090 from './patternit_progress_anim_boomerang090.png';
import image091 from './patternit_progress_anim_boomerang091.png';
import image092 from './patternit_progress_anim_boomerang092.png';
import image093 from './patternit_progress_anim_boomerang093.png';
import image094 from './patternit_progress_anim_boomerang094.png';
import image095 from './patternit_progress_anim_boomerang095.png';
import image096 from './patternit_progress_anim_boomerang096.png';
import image097 from './patternit_progress_anim_boomerang097.png';
import image098 from './patternit_progress_anim_boomerang098.png';
import image099 from './patternit_progress_anim_boomerang099.png';
import image100 from './patternit_progress_anim_boomerang100.png';
import image101 from './patternit_progress_anim_boomerang101.png';
import image102 from './patternit_progress_anim_boomerang102.png';
import image103 from './patternit_progress_anim_boomerang103.png';
import image104 from './patternit_progress_anim_boomerang104.png';
import image105 from './patternit_progress_anim_boomerang105.png';
import image106 from './patternit_progress_anim_boomerang106.png';
import image107 from './patternit_progress_anim_boomerang107.png';
import image108 from './patternit_progress_anim_boomerang108.png';
import image109 from './patternit_progress_anim_boomerang109.png';
import image110 from './patternit_progress_anim_boomerang110.png';
import image111 from './patternit_progress_anim_boomerang111.png';
import image112 from './patternit_progress_anim_boomerang112.png';
import image113 from './patternit_progress_anim_boomerang113.png';
import image114 from './patternit_progress_anim_boomerang114.png';
import image115 from './patternit_progress_anim_boomerang115.png';
import image116 from './patternit_progress_anim_boomerang116.png';
import image117 from './patternit_progress_anim_boomerang117.png';
import image118 from './patternit_progress_anim_boomerang118.png';
import image119 from './patternit_progress_anim_boomerang119.png';
import image120 from './patternit_progress_anim_boomerang120.png';
import image121 from './patternit_progress_anim_boomerang121.png';
import image122 from './patternit_progress_anim_boomerang122.png';
import image123 from './patternit_progress_anim_boomerang123.png';
import image124 from './patternit_progress_anim_boomerang124.png';


export const progressImages = [
  image000,
  image001,
  image002,
  image003,
  image004,
  image005,
  image006,
  image007,
  image008,
  image009,
  image010,
  image011,
  image012,
  image013,
  image014,
  image015,
  image016,
  image017,
  image018,
  image019,
  image020,
  image021,
  image022,
  image023,
  image024,
  image025,
  image026,
  image027,
  image028,
  image029,
  image030,
  image031,
  image032,
  image033,
  image034,
  image035,
  image036,
  image037,
  image038,
  image039,
  image040,
  image041,
  image042,
  image043,
  image044,
  image045,
  image046,
  image047,
  image048,
  image049,
  image050,
  image051,
  image052,
  image053,
  image054,
  image055,
  image056,
  image057,
  image058,
  image059,
  image060,
  image061,
  image062,
  image063,
  image064,
  image065,
  image066,
  image067,
  image068,
  image069,
  image070,
  image071,
  image072,
  image073,
  image074,
  image075,
  image076,
  image077,
  image078,
  image079,
  image080,
  image081,
  image082,
  image083,
  image084,
  image085,
  image086,
  image087,
  image088,
  image089,
  image090,
  image091,
  image092,
  image093,
  image094,
  image095,
  image096,
  image097,
  image098,
  image099,
  image100,
  image101,
  image102,
  image103,
  image104,
  image105,
  image106,
  image107,
  image108,
  image109,
  image110,
  image111,
  image112,
  image113,
  image114,
  image115,
  image116,
  image117,
  image118,
  image119,
  image120,
  image121,
  image122,
  image123,
  image124,
];
