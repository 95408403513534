import { useEffect, useRef } from "react";

interface AnimationProps {
  images: HTMLImageElement[];
  className?: string;
  onEnd?: () => void;
}

export default function Animation(props: AnimationProps) {
  const container = useRef<HTMLDivElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  let context: CanvasRenderingContext2D | null;

  let index = 0;
  let lastTime: number;
  const fpsInterval = 1000 / 30;

  const animate = () => {
    if (context === null || canvas.current === null) {
      return;
    }

    window.requestAnimationFrame(animate);

    const now = Date.now();
    const elapsed = now - lastTime;

    if (elapsed >= fpsInterval) {
      lastTime = now - (elapsed % fpsInterval);

      context!.clearRect(0, 0, canvas.current!.width, canvas.current!.height);
      context!.drawImage(props.images[index], 0, 0, canvas.current!.width, canvas.current!.height);

      index = index + 1;
      if (index === props.images.length) {
        index = 0;
        props.onEnd?.();
      }
    }
  }



  const startAnimation = () => {
    lastTime = Date.now();
    context = canvas.current?.getContext('2d')!;
    animate();
  }

  useEffect(() => {
    startAnimation();
  }, [canvas]);

  const width = container.current?.clientWidth ?? 320;
  const height = container.current?.clientHeight ?? 200;
  const aspectRatio = (props.images.length > 0 && props.images[0].width > 0 && props.images[0].height > 0) ? props.images[0].width / props.images[0].height : 1.6;

  return <div ref={container}>
    <canvas className={props.className} width={width} height={height} style={{ aspectRatio: aspectRatio }} ref={canvas}></canvas>
  </div>
}