import { useRef, useState } from 'react';

import { useUserMedia } from '../../hooks';
import TopMenu from '../../TopMenu';
import { RenderType } from '../../types';
import { CameraControls } from './CameraControls';
import CameraControlsMenu from './CameraControlsMenu';
import classes from './CameraPage.module.scss';
import { VideoRender } from '../videoRender/VideoRender';
import imagesService from '../../services/ImagesService';
import { startVideoRecording, stopVideoRecording } from './videoRecorder';

export function CameraPage() {
    const tileDimensions = { width: 64, height: 64 };

    const { stream } = useUserMedia({ audio: false, video: { facingMode: 'environment' } });
    const [tileCropSize, setTileCropSize] = useState(2);

    const track = stream?.getVideoTracks()[0];

    const [currentRenderType, setCurrentRenderType] = useState<RenderType>(RenderType.Triangles1);

    const tileCanvas = useRef<HTMLCanvasElement>(null);
    const video = useRef<HTMLVideoElement>(null);

    console.log(`Render type: ${RenderType[currentRenderType]}`);

    if (video.current) {
        video.current.setAttribute('autoplay', '');
        video.current.setAttribute('muted', '');
        video.current.setAttribute('playsinline', '')
        video.current.srcObject = stream;
        video.current.onloadedmetadata = () => {
            video.current?.play();
        }
    }

    const onTakePicture = () => {
        const thumbnailDataUrl = tileCanvas.current?.toDataURL()!;

        tileCanvas.current?.toBlob(tileBlob => {
            if (tileBlob) {
                imagesService.addImage(tileBlob, thumbnailDataUrl, currentRenderType);
            }
        });
    };

    const onStartVideoRecording = () => {
        const thumbnailDataUrl = tileCanvas.current?.toDataURL()!;
        startVideoRecording(currentRenderType, tileCanvas.current!.getContext('2d')!, thumbnailDataUrl);
    }

    const onFinishVideoRecording = () => {
        stopVideoRecording();
    }

    return (
        <div className={classes.page} style={{ overflow: 'hidden' }}>
            <TopMenu />

            <VideoRender renderType={currentRenderType} tileCanvas={tileCanvas.current} video={video.current}></VideoRender>

            <CameraControlsMenu
                renderType={currentRenderType}
                onRenderTypeChanged={setCurrentRenderType}
                tileCropSize={tileCropSize}
                onTileCropSizeChanged={setTileCropSize}
                track={track}></CameraControlsMenu>

            <CameraControls onTakePicture={onTakePicture} onStartVideoRecording={onStartVideoRecording} onFinishVideoRecording={onFinishVideoRecording}>
                <canvas
                    id="tileCanvas"
                    ref={tileCanvas}
                    className={classes.preview}
                    width={tileDimensions.width}
                    height={tileDimensions.height}></canvas>
            </CameraControls>            

            <video
                autoPlay
                ref={video}
                style={{ display: 'none' }}/>
        </div>
    );
}
