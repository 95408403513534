import { PatternType } from "../types";

export function updatePatternFilter(type: PatternType, setPatternFilter: (type: PatternType) => void): void
{
  setPatternFilter(type);
  if (type === 'triangles') {
    document
      .getElementById('Triangles1')!
      .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }
  else if (type === 'squares') {
    document
      .getElementById('Squares1')!
      .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }
  else if (type === 'rhombuses') {
    document
      .getElementById('Rhombuses1')!
      .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }
}