import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';

import { useObservableState } from 'observable-hooks';
import TopMenu from '../../TopMenu';
import { useImageUpdates } from '../../hooks/useImageUpdates';
import { BackIcon, HourglassIcon, PlayIcon, SelectableIcon, SelectedIcon } from '../../icons/Icons';
import backendService from '../../services/BackendService';
import collectionsService from '../../services/CollectionsService';
import imagesService from '../../services/ImagesService';
import routerService from '../../services/RouterService';
import { Image } from '../../types';
import { ItemBottomMenu, MenuItem } from '../bottomMenu/ItemBottomMenu';
import classes from './CollectionPage.module.scss';
import classNames from 'classnames';

export function CollectionPage() {
  const navigate = useNavigate();
  const queryParams = useSearchParams()[0];

  const images = useObservableState(imagesService.onImages(), []);
  const collections = useObservableState(collectionsService.onCollections(), []);

  const [inSelectionMode, setInSelectionMode] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const collectionId = queryParams.get('collectionId');
  const collection =  collectionsService.collectionById(collectionId);
  const collectionImages = collection?.imageIds.map(x => imagesService.imageById(x)).filter(x => x !== null).map(x => x!) ?? [];

  const longPressBind = useLongPress(() => {
    if ('vibrate' in navigator) navigator.vibrate([100, 100, 100]);
    setInSelectionMode(!inSelectionMode)
  },
  {
    filterEvents: (event) => true,
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: true
  });

  useImageUpdates(collectionImages);

  const onImageClicked = (id: string) => {
    const index = selectedImages.findIndex(x => x === id);
    if (index === -1) {
      setSelectedImages([...selectedImages, id]);
    }
    else {
      selectedImages.splice(index, 1);
      setSelectedImages([...selectedImages]);
    }
  }

  const onShare = () => {
    alert('not implemented');
  }

  const onDelete = () => {
    imagesService.deleteImages(selectedImages.map(x => imagesService.imageById(x)!));
    setInSelectionMode(false);
    setSelectedImages([]);
  }

  const menuItems: MenuItem[] =
  [
    {
      title: 'adjust',
      icon: 'adjust',
      onClick: () => { alert('not implemented'); }
    },
    {
      title: 'share',
      icon: 'share',
      onClick: onShare,
    },
    {
      title: 'delete',
      icon: 'delete',
      onClick: onDelete,
    }
  ];

  const renderImageItem = (image: Image, i: number, onClick: () => void) => {
    return <div onClick={onClick}>
      <div className={classes.iconContainer}>
        { inSelectionMode 
          ? selectedImages.find(x => x === image.imageId) !== undefined
            ? <SelectedIcon className={classes.selectedIcon}></SelectedIcon>
            : <SelectableIcon className={classes.selectedIcon}></SelectableIcon>
           : null }
      </div>

      <div className={classes.iconContainer}>
        {image.thumbnailDataUrl !== undefined || image.isRendering ? <HourglassIcon className={classes.imageIcon}></HourglassIcon> : null }
        {image.isVideo && !image.isRendering ? <PlayIcon className={classes.imageIcon}></PlayIcon> : null }
      </div>

      <img className={classNames(classes.image, selectedImages.find(x => x === image.imageId) !== undefined ? classes.selectedImage : null)} src={image.thumbnailDataUrl ? image.thumbnailDataUrl : backendService.getGetImageThumbnailUrl(image.imageId, image.renderType)} alt={`pattern${i + 1}`}></img>
    </div>;
  }

  return <div className={classes.page}>
    <TopMenu />

    <div className={classes.header}>
      <div className={classes.left} onClick={_ => navigate('/gallery')}><BackIcon className={classes.icon}></BackIcon></div>
      <div className={classes.middle}>{collection?.name}</div>
      <div className={classes.right}></div>
    </div>

    <div className={classes.imageContainer}>
      {collectionImages.map((image, i) => 
        <div {...longPressBind()} className={classes.imageItem} key={`${image.imageId}-${image.renderType}`}>
          { inSelectionMode
            ? renderImageItem(image, i, () => onImageClicked(image.imageId))
            : renderImageItem(image, i, () => { if (!image.isRendering) { navigate(routerService.getFullscreenImageUrl(image)); } }) }
        </div>)}
    </div>

    { inSelectionMode && selectedImages.length > 0 ? <ItemBottomMenu menuItems={menuItems} onBack={() => { setSelectedImages([]); setInSelectionMode(false); }}></ItemBottomMenu> : null }
  </div>;
}
