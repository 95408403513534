export interface Image {
  readonly imageId: string;
  readonly collectionId?: string;

  readonly date: string;

  readonly renderType: RenderType;

  readonly filterType: FilterType;
  readonly brightness: number;
  readonly contrast: number;
  readonly hue: number;
  readonly saturation: number;

  readonly thumbnailDataUrl?: string;

  readonly isVideo?: boolean;
  readonly isRendering?: boolean;
}

export interface ImageParameter {
  readonly imageId: string;
  readonly renderType: RenderType;
  readonly filterType: FilterType;
  readonly brightness: number;
  readonly contrast: number;
  readonly hue: number;
  readonly saturation: number;
}

export interface ImageParameterOptionals {
  readonly imageId: string;
  readonly renderType?: RenderType;
  readonly filterType?: FilterType;
  readonly brightness?: number;
  readonly contrast?: number;
  readonly hue?: number;
  readonly saturation?: number;
}

export interface Collection {
  readonly id: string;
  readonly name: string;
  readonly date: string;
  readonly imageIds: string[];
}

export enum RenderType {
  Triangles1,
  Triangles2,
  Triangles3,
  Triangles4,
  Triangles5,
  Triangles6,
  Triangles7,
  Triangles8,
  Triangles9,
  Triangles10,
  Triangles11,
  Triangles12,
  Triangles13,
  Triangles14,
  Triangles15,
  Triangles16,
  Triangles17,

  Squares1,
  Squares2,
  Squares3,
  Squares4,
  Squares5,
  Squares6,
  Squares7,
  Squares8,
  Squares9,
  Squares10,
  Squares11,
  Squares12,
  
  Rhombuses1,
  Rhombuses2,
  Rhombuses3,
  Rhombuses4,
  Rhombuses5,
  Rhombuses6,
  Rhombuses7,
  Rhombuses8,
  Rhombuses9,
  Rhombuses10,
}

export const RenderTypeCount = 39;

export enum FilterType {
  None,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
}

export type PatternType = 'triangles'| 'squares' | 'rhombuses';

export const squarePatterns = [
  RenderType.Squares1,
  RenderType.Squares2,
  RenderType.Squares3,
  RenderType.Squares4,
  RenderType.Squares5,
  RenderType.Squares6,
  RenderType.Squares7,
  RenderType.Squares8,
  RenderType.Squares9,
  RenderType.Squares10,
  RenderType.Squares11,
  RenderType.Squares12,
];

export const trianglePatterns = [
  RenderType.Triangles1,
  RenderType.Triangles2,
  RenderType.Triangles3,
  RenderType.Triangles4,
  RenderType.Triangles5,
  RenderType.Triangles6,
  RenderType.Triangles7,
  RenderType.Triangles8,
  RenderType.Triangles9,
  RenderType.Triangles10,
  RenderType.Triangles11,
  RenderType.Triangles12,
  RenderType.Triangles13,
  RenderType.Triangles14,
  RenderType.Triangles15,
  RenderType.Triangles16,
  RenderType.Triangles17,
];


export const rhombusPatterns = [
  RenderType.Rhombuses1,
  RenderType.Rhombuses2,
  RenderType.Rhombuses3,
  RenderType.Rhombuses4,
  RenderType.Rhombuses5,
  RenderType.Rhombuses6,
  RenderType.Rhombuses7,
  RenderType.Rhombuses8,
  RenderType.Rhombuses9,
  RenderType.Rhombuses10,
];

export const allPatterns = [
  ...trianglePatterns,
  ...squarePatterns,
  ...rhombusPatterns,
];