
import { CloseIcon } from '../../icons/Icons';
import classes from './Dialogs.module.scss';

interface AddToCollectionDialogProps {
    onSelection: (type: 'new' | 'select' | 'close') => void;
}

export function AddToCollectionDialog(props: AddToCollectionDialogProps) {
    return <div className={classes.root}>
        <div className={classes.dialog}>
            <div className={classes.close}>
                <CloseIcon className={classes.icon} onClick={() => props.onSelection('close')}></CloseIcon>
            </div>

            <div className={classes.title}>Add to collection</div>

            <div className={classes.inputContainer}>
                <button className={classes.inputButtonNormal} onClick={() => props.onSelection('new')}>New</button>
            </div>

            <div className={classes.inputContainer}>
                <button className={classes.inputButtonNormal} onClick={() => props.onSelection('select')}>Select</button>
            </div>
        </div>
    </div>
}
