import { useEffect } from "react";
import { Image } from "../types";
import imagesService from "../services/ImagesService";

export function useImageUpdates(images: Image[]) {
    useEffect(() => {
        const isRendering = images.filter(x => x.isRendering).length > 0;

        if (isRendering) {
            const timer = setInterval(() => {
                imagesService.fetchImages();
                const isStillRendering = imagesService.allImages().filter(x => x.isRendering).length > 0
                console.log(`isStillRendering: ${isStillRendering}`);
            }, 1000);
            return () => clearTimeout(timer);
        }
        else {
            return () => {};
        }
      }, [images]);
}